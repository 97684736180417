// Buttons
.mat-mdc-button,
.mat-mdc-mini-fab,
.mat-mdc-icon-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  @apply inline-flex items-center justify-center h-12 min-h-[48px] max-h-[48px] hover:opacity-90 hover:transition-opacity transition-opacity #{!important};
}

.mat-mdc-button.mat-mdc-button-base {
  &:not(.mat-mdc-raised-button),
  &:not(.mat-mdc-outlined-button),
  &:not(.mat-mdc-unelevated-button) {
    @apply bg-gray-100;
  }
}

.mat-mdc-mini-fab {
  @apply min-w-[48px];
}

.mat-mdc-fab,
.mat-mdc-mini-fab,
.mdc-button--raised {
  @apply shadow-lg #{!important};
}

.mdc-button,
.mdc-button--raised,
.mdc-button--outlined,
.mdc-button--unelevated {
  @apply rounded-xl px-5 #{!important};
}


// Checkbox
.mat-mdc-checkbox {
  .mdc-form-field {
    .mdc-checkbox {
      @apply h-5 min-h-[20px] max-h-[20px] w-5 max-w-[20px] min-w-[20px] p-0;

      .mdc-checkbox__ripple,
      .mat-mdc-checkbox-ripple,
      .mat-mdc-checkbox-touch-target {
        @apply h-8 w-8;
      }

      .mdc-checkbox__ripple,
      .mat-mdc-checkbox-ripple {
        top: -7px;
        left: -7px;
      }

      .mdc-checkbox__native-control:not([disabled]):focus ~ .mdc-checkbox__ripple {
        opacity: .08;
      }

      .mdc-checkbox__native-control {
        @apply h-full max-h-full min-h-full w-full max-w-full min-w-full;
      }

      .mdc-checkbox__background {
        @apply inset-0 border-gray-300 rounded #{!important};
      }

      .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background {
        @apply border-transparent #{!important};
      }
    }
  }
}


// Form field
.mat-mdc-form-field.mat-form-field-appearance-fill {
  &.mat-form-field-disabled {
    opacity: 0.7 !important;
  }

  &.mat-form-field-invalid {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        @apply border-red-500 #{!important};
      }
    }
  }

  &.mat-focused:not(.mat-form-field-invalid) {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        @apply border-blue-800 #{!important};
      }
    }
  }

  .mat-mdc-text-field-wrapper {
    @apply overflow-visible px-0 mt-4 rounded-lg;

    &.mdc-text-field--no-label {
      @apply mt-0;
    }

    &:not(.mdc-text-field--disabled) {
      @apply bg-white;
    }

    .mat-mdc-form-field-flex {
      @apply flex items-center justify-center relative min-h-[48px] border border-gray-200 rounded-lg px-4 #{!important};

      .mat-mdc-form-field-infix {
        @apply static p-0 min-h-[auto] #{!important};

        label {
          @apply top-0 left-0 font-[500] text-sm;

          &.mdc-floating-label--float-above {
            transform: translateY(-106%) scale(1);
          }
        }

        textarea {
          @apply my-2;
        }
      }

      .mat-mdc-form-field-icon-prefix,
      .mat-mdc-form-field-icon-suffix {
        @apply flex items-center justify-center space-x-1 text-gray-500;
      }

      .mat-mdc-form-field-icon-suffix {
        @apply p-0;
      }

      .mat-icon {
        @apply p-0 h-5 w-5 min-w-[20px] max-w-[20px] text-[20px];
      }

      .mat-mdc-button-base {
        @apply h-9 min-h-[36px] max-h-[36px] #{!important};

        &.mat-mdc-fab,
        &.mat-mdc-mini-fab,
        &.mat-mdc-icon-button {
          @apply w-9 p-0;
        }
      }

      .mat-mdc-select {
        .mat-mdc-select-trigger {
          @apply space-x-1.5 border-none bg-transparent px-0 h-auto min-h-0;

          /*.mat-mdc-select-value {
            @apply leading-none;
          }*/

          .mat-mdc-select-arrow-wrapper {
            @apply transform-none;
          }
        }
      }
    }

    &.mdc-text-field--disabled {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-icon-suffix {
          @apply opacity-40;
        }
      }
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      @apply px-0;

      mat-hint,
      mat-error {
        @apply block text-sm;
      }

      mat-hint {
        @apply text-[12px] text-gray-500;
      }
    }
  }

  &:hover,
  &.mat-focused {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-focus-overlay {
        @apply hidden;
      }
    }
  }

  .mdc-line-ripple {
    @apply hidden;
  }
}


// Select
.mat-mdc-form-field:not(.mat-mdc-paginator-page-size-select) .mat-mdc-select {
  .mat-mdc-select-trigger {
    @apply h-12 max-h-[48px] min-h-[48px] border border-gray-200 bg-white px-4 rounded-lg;
  }

  &.mat-mdc-select-disabled {
    .mat-mdc-select-arrow-wrapper {
      .mat-mdc-select-arrow {
        @apply opacity-50;
      }
    }
  }
}

// Overlay pane
.cdk-overlay-container {
  .cdk-overlay-pane {
    .mdc-menu-surface {
      @apply shadow-lg border border-gray-200 rounded-lg #{!important};
    }

    &.mat-datepicker-popup {
      .mat-datepicker-content {
        @apply shadow-lg;

        .mat-calendar-header {
          .mat-calendar-previous-button,
          .mat-calendar-next-button {
            @apply h-10 min-h-[40px] #{!important};
          }
        }

        .mat-calendar-content {
          .mat-calendar-body-cell-content {
            @apply rounded;
          }
        }
      }
    }

    .mat-mdc-snack-bar-container {
      .mdc-snackbar__surface {
        @apply bg-black-900;

        .mat-mdc-snack-bar-label {
          @apply text-white;
        }

        .mat-mdc-snack-bar-actions {
          .mat-mdc-button-base {
            @apply h-9 min-h-0 bg-transparent #{!important};
          }
        }
      }
    }
  }

  .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      @apply shadow-lg rounded-lg #{!important};

      .mat-mdc-dialog-title,
      .mat-mdc-dialog-content {
        font-family: "Poppins", sans-serif;
        font-size: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        @apply leading-normal;
      }

      .mat-mdc-dialog-title {
        @apply text-2xl font-semibold;
      }
    }
  }

  .mdc-tooltip__surface {
    @apply bg-black-900;
  }

  .cdk-overlay-backdrop-showing:not(.mat-overlay-transparent-backdrop) {
    background-color: rgba(241, 241, 241, .73);
  }
}

// Chips
.mat-mdc-chip-set {
  .mat-mdc-chip {
    @apply h-8 max-h-[32px] rounded;

    &:not(.mat-mdc-chip-selected) {
      @apply bg-black-100;
    }

    .mat-mdc-chip-action-label {
      @apply text-sm;
    }

    .mat-mdc-chip-action:before {
      @apply border-none;
    }
  }
}

// Expansion panel
.mat-accordion {
  @apply space-y-3;

  .mat-expansion-panel {
    @apply shadow-lg #{!important};

    .mat-action-row {
      .mat-mdc-button {
        @apply min-h-[36px] h-9 #{!important};
      }
    }
  }
}


// Paginator
.mat-mdc-paginator {
  .mat-mdc-paginator-page-size {
    .mat-mdc-select {

    }
  }
}

// Slide toggle
.mat-mdc-slide-toggle {
  .mdc-switch {
    @apply w-11 #{!important};

    .mdc-switch__track {
      @apply h-6 w-11 rounded-xl #{!important};
    }

    .mdc-switch__handle-track {
      @apply translate-x-[10%];

      .mdc-switch__handle {
        @apply before:bg-white after:bg-white #{!important};

        .mdc-switch__icons {
          @apply hidden;
        }
      }
    }

    &.mdc-switch--selected {
      .mdc-switch__handle-track {
        @apply translate-x-[90%];
      }
    }
  }
}

// Tabs
.mat-mdc-tab-group {
  .mat-mdc-tab-header {
    .mat-mdc-tab-labels {
      @apply space-x-1;

      .mat-mdc-tab {
        @apply bg-gray-200 rounded-md;

        &.mdc-tab--active {
          @apply bg-black-900;

          .mdc-tab__text-label {
            @apply text-white;
          }
        }

        .mat-mdc-tab-ripple {
          @apply rounded-md;
        }
      }

      .mdc-tab-indicator {
        @apply hidden;
      }
    }
  }
}

// Table
.mat-mdc-table {
  border-collapse: unset;
  @apply bg-transparent #{!important};

  tr.mat-mdc-header-row {
    height: 48px;
  }

  .mat-mdc-header-cell {
    border: none !important;
    background-color: transparent !important;

    &:first-child {
      @apply pl-3 md:pl-6;
    }

    &:last-child {
      @apply pr-3 md:pr-6;
    }
  }

  tr.mat-mdc-row {
    &:hover {
      @apply bg-gray-100 #{!important};
    }

    .mat-mdc-cell:first-child {
      border-left-width: 1px;
      border-left-style: solid;
      @apply pl-3 md:pl-6;
    }

    .mat-mdc-cell:last-child {
      border-right-width: 1px;
      border-right-style: solid;
      @apply pr-3 md:pr-6;
    }
  }

  tr.mat-mdc-row:first-child {
    .mat-mdc-cell {
      border-top-width: 1px;
      border-top-style: solid;
    }

    .mat-mdc-cell:first-child {
      border-left-width: 1px;
      border-left-style: solid;
      border-top-left-radius: 8px;
    }

    .mat-mdc-cell:last-child {
      border-right-width: 1px;
      border-right-style: solid;
      border-top-right-radius: 8px;
    }
  }

  tr.mat-mdc-row:last-child {
    .mat-mdc-cell {
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: #DFE0E2;
    }

    .mat-mdc-cell:first-child {
      border-left-width: 1px;
      border-left-style: solid;
      border-bottom-left-radius: 8px;
    }

    .mat-mdc-cell:last-child {
      border-right-width: 1px;
      border-right-style: solid;
      border-bottom-right-radius: 8px;
    }
  }
}

// Sidebar
mat-drawer-container {
  .mat-drawer {
    width: 276px;
    padding: 32px 24px;
  }
}

// Toolbar
mat-toolbar.af-toolbar {
  @apply h-14 bg-white border-b #{!important};

  .hamburger-button.mat-mdc-icon-button {
    @apply w-10 h-10 min-h-[10px] #{!important};
  }
}

// Icon
.mat-icon {
  @apply inline-flex items-center justify-center #{!important};
}

// Button toggle group
.mat-button-toggle-group {
  @apply border-none space-x-1 #{!important};

  .mat-button-toggle {
    @apply bg-white border-none rounded-full #{!important};

    &.mat-button-toggle-checked {
      @apply bg-black-900 text-white #{!important};
    }

    .mat-button-toggle-button {
      .mat-button-toggle-label-content {
        line-height: 40px;
        padding: 0 24px;
      }
    }

    .mat-button-toggle-ripple {
      @apply rounded-full;
    }
  }
}
