.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-chip {
  height: 32px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button .mdc-radio {
  padding: calc((40px - 20px) / 2);
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (40px - 20px) / 2);
  left: calc(-1 * (40px - 20px) / 2);
  width: 40px;
  height: 40px;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  top: calc((40px - 40px) / 2);
  right: calc((40px - 40px) / 2);
  left: calc((40px - 40px) / 2);
  width: 40px;
  height: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-one-line {
  height: 48px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-radio.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-switch.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-image.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-video.mdc-list-item--with-one-line {
  height: 72px;
}
.mat-mdc-list-item.mdc-list-item--with-two-lines {
  height: 64px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines {
  height: 72px;
}
.mat-mdc-list-item.mdc-list-item--with-three-lines {
  height: 88px;
}

.mat-mdc-list-option-with-trailing-avatar.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-option-with-trailing-avatar.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mat-mdc-tab-header .mdc-tab {
  height: 48px;
}

.mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: var(--mdc-checkbox-touch-target-size, 40px);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tree-node {
  min-height: 48px;
}

body.light .mat-ripple-element,
body .light .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
body.light .mat-mdc-option,
body .light .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
body.light .mat-mdc-option:hover:not(.mdc-list-item--disabled), body.light .mat-mdc-option:focus:not(.mdc-list-item--disabled), body.light .mat-mdc-option.mat-mdc-option-active, body.light .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
body .light .mat-mdc-option:hover:not(.mdc-list-item--disabled),
body .light .mat-mdc-option:focus:not(.mdc-list-item--disabled),
body .light .mat-mdc-option.mat-mdc-option-active,
body .light .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}
body.light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
body .light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #171717);
}
body.light .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
body .light .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #5b21b6);
}
body.light .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
body .light .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-optgroup-label,
body .light .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
body.light .mat-pseudo-checkbox,
body .light .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
body.light .mat-pseudo-checkbox::after,
body .light .mat-pseudo-checkbox::after {
  color: #fafafa;
}
body.light .mat-pseudo-checkbox-disabled,
body .light .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
body.light .mat-primary .mat-pseudo-checkbox-checked,
body.light .mat-primary .mat-pseudo-checkbox-indeterminate,
body .light .mat-primary .mat-pseudo-checkbox-checked,
body .light .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #171717;
}
body.light .mat-pseudo-checkbox-checked,
body.light .mat-pseudo-checkbox-indeterminate,
body.light .mat-accent .mat-pseudo-checkbox-checked,
body.light .mat-accent .mat-pseudo-checkbox-indeterminate,
body .light .mat-pseudo-checkbox-checked,
body .light .mat-pseudo-checkbox-indeterminate,
body .light .mat-accent .mat-pseudo-checkbox-checked,
body .light .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #5b21b6;
}
body.light .mat-warn .mat-pseudo-checkbox-checked,
body.light .mat-warn .mat-pseudo-checkbox-indeterminate,
body .light .mat-warn .mat-pseudo-checkbox-checked,
body .light .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ef4444;
}
body.light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
body.light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
body .light .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
body .light .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
body.light .mat-app-background, body.light.mat-app-background,
body .light .mat-app-background,
body .light.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
body.light .mat-elevation-z0, body.light .mat-mdc-elevation-specific.mat-elevation-z0,
body .light .mat-elevation-z0,
body .light .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z1, body.light .mat-mdc-elevation-specific.mat-elevation-z1,
body .light .mat-elevation-z1,
body .light .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z2, body.light .mat-mdc-elevation-specific.mat-elevation-z2,
body .light .mat-elevation-z2,
body .light .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z3, body.light .mat-mdc-elevation-specific.mat-elevation-z3,
body .light .mat-elevation-z3,
body .light .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z4, body.light .mat-mdc-elevation-specific.mat-elevation-z4,
body .light .mat-elevation-z4,
body .light .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z5, body.light .mat-mdc-elevation-specific.mat-elevation-z5,
body .light .mat-elevation-z5,
body .light .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z6, body.light .mat-mdc-elevation-specific.mat-elevation-z6,
body .light .mat-elevation-z6,
body .light .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z7, body.light .mat-mdc-elevation-specific.mat-elevation-z7,
body .light .mat-elevation-z7,
body .light .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z8, body.light .mat-mdc-elevation-specific.mat-elevation-z8,
body .light .mat-elevation-z8,
body .light .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z9, body.light .mat-mdc-elevation-specific.mat-elevation-z9,
body .light .mat-elevation-z9,
body .light .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z10, body.light .mat-mdc-elevation-specific.mat-elevation-z10,
body .light .mat-elevation-z10,
body .light .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z11, body.light .mat-mdc-elevation-specific.mat-elevation-z11,
body .light .mat-elevation-z11,
body .light .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z12, body.light .mat-mdc-elevation-specific.mat-elevation-z12,
body .light .mat-elevation-z12,
body .light .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z13, body.light .mat-mdc-elevation-specific.mat-elevation-z13,
body .light .mat-elevation-z13,
body .light .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z14, body.light .mat-mdc-elevation-specific.mat-elevation-z14,
body .light .mat-elevation-z14,
body .light .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z15, body.light .mat-mdc-elevation-specific.mat-elevation-z15,
body .light .mat-elevation-z15,
body .light .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z16, body.light .mat-mdc-elevation-specific.mat-elevation-z16,
body .light .mat-elevation-z16,
body .light .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z17, body.light .mat-mdc-elevation-specific.mat-elevation-z17,
body .light .mat-elevation-z17,
body .light .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z18, body.light .mat-mdc-elevation-specific.mat-elevation-z18,
body .light .mat-elevation-z18,
body .light .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z19, body.light .mat-mdc-elevation-specific.mat-elevation-z19,
body .light .mat-elevation-z19,
body .light .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z20, body.light .mat-mdc-elevation-specific.mat-elevation-z20,
body .light .mat-elevation-z20,
body .light .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z21, body.light .mat-mdc-elevation-specific.mat-elevation-z21,
body .light .mat-elevation-z21,
body .light .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z22, body.light .mat-mdc-elevation-specific.mat-elevation-z22,
body .light .mat-elevation-z22,
body .light .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z23, body.light .mat-mdc-elevation-specific.mat-elevation-z23,
body .light .mat-elevation-z23,
body .light .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
body.light .mat-elevation-z24, body.light .mat-mdc-elevation-specific.mat-elevation-z24,
body .light .mat-elevation-z24,
body .light .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

body.light .mat-mdc-card,
body .light .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}
body.light .mat-mdc-card-outlined,
body .light .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}
body.light .mat-mdc-card-subtitle,
body .light .mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
body.light .mat-mdc-progress-bar,
body .light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #171717;
}
body.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
body .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(23, 23, 23, 0.25)'/%3E%3C/svg%3E");
}
body.light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
body .light .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(23, 23, 23, 0.25);
}
body.light .mat-mdc-progress-bar.mat-accent,
body .light .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #5b21b6;
}
body.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
body .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(91, 33, 182, 0.25)'/%3E%3C/svg%3E");
}
body.light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
body .light .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(91, 33, 182, 0.25);
}
body.light .mat-mdc-progress-bar.mat-warn,
body .light .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ef4444;
}
body.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
body .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
}
body.light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
body .light .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
}
body.light .mat-mdc-tooltip,
body .light .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
body.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
body .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
body.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
body .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  body.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
  body .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  body.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder,
  body .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
body.light .mdc-text-field .mdc-text-field__input,
body .light .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #171717);
}
body.light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
body .light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
body.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
body.light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter,
body .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
body .light .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
body.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
body .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
body.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
body .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
body.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix,
body .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
body.light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix,
body .light .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
body.light .mdc-text-field--filled .mdc-text-field__ripple::before,
body.light .mdc-text-field--filled .mdc-text-field__ripple::after,
body .light .mdc-text-field--filled .mdc-text-field__ripple::before,
body .light .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
body.light .mdc-text-field--filled:hover .mdc-text-field__ripple::before, body.light .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
body .light .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
body .light .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
body.light .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, body.light .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before,
body .light .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
body .light .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
body.light .mdc-text-field--filled:not(.mdc-text-field--disabled),
body .light .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
body.light .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
body .light .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
body.light .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
body .light .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
body.light .mdc-text-field--filled .mdc-line-ripple::after,
body .light .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #171717);
}
body.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body.light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
body .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body .light .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
body.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body.light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
body .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body .light .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
body.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
body .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body .light .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #171717);
}
[dir=rtl] body.light .mdc-text-field--outlined.mdc-text-field--with-leading-icon, [dir=rtl] body .light .mdc-text-field--outlined.mdc-text-field--with-leading-icon, body.light .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl], body .light .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] body.light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, [dir=rtl] body .light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, body.light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl], body .light .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

body.light .mdc-text-field--outlined .mdc-text-field__ripple::before,
body.light .mdc-text-field--outlined .mdc-text-field__ripple::after,
body .light .mdc-text-field--outlined .mdc-text-field__ripple::before,
body .light .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] body.light .mdc-text-field--with-leading-icon.mdc-text-field--outlined, [dir=rtl] body .light .mdc-text-field--with-leading-icon.mdc-text-field--outlined, body.light .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl], body .light .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
body.light .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
body .light .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(23, 23, 23, 0.87);
}
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ef4444);
}
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ef4444);
}
body.light .mdc-text-field--invalid .mdc-text-field__input,
body .light .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ef4444);
}
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ef4444);
}
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body .light .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.light .mdc-text-field--disabled .mdc-text-field__input,
body .light .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  body.light .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  body .light .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  body.light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  body .light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
body.light .mdc-text-field--disabled .mdc-floating-label,
body .light .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
body .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mdc-text-field--disabled .mdc-text-field-character-counter,
body.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
body .light .mdc-text-field--disabled .mdc-text-field-character-counter,
body .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mdc-text-field--disabled .mdc-text-field__icon--leading,
body .light .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
body.light .mdc-text-field--disabled .mdc-text-field__icon--trailing,
body .light .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
body.light .mdc-text-field--disabled .mdc-text-field__affix--prefix,
body .light .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mdc-text-field--disabled .mdc-text-field__affix--suffix,
body .light .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mdc-text-field--disabled .mdc-line-ripple::before,
body .light .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
body.light .mdc-text-field--disabled .mdc-notched-outline__leading,
body.light .mdc-text-field--disabled .mdc-notched-outline__notch,
body.light .mdc-text-field--disabled .mdc-notched-outline__trailing,
body .light .mdc-text-field--disabled .mdc-notched-outline__leading,
body .light .mdc-text-field--disabled .mdc-notched-outline__notch,
body .light .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.light .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  body .light .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  body .light .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.light .mdc-text-field--disabled .mdc-floating-label,
  body .light .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
  body .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.light .mdc-text-field--disabled .mdc-text-field-character-counter,
  body.light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
  body .light .mdc-text-field--disabled .mdc-text-field-character-counter,
  body .light .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.light .mdc-text-field--disabled .mdc-text-field__icon--leading,
  body .light .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.light .mdc-text-field--disabled .mdc-text-field__icon--trailing,
  body .light .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.light .mdc-text-field--disabled .mdc-text-field__affix--prefix,
  body .light .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.light .mdc-text-field--disabled .mdc-text-field__affix--suffix,
  body .light .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.light .mdc-text-field--disabled .mdc-line-ripple::before,
  body .light .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.light .mdc-text-field--disabled .mdc-notched-outline__leading,
  body.light .mdc-text-field--disabled .mdc-notched-outline__notch,
  body.light .mdc-text-field--disabled .mdc-notched-outline__trailing,
  body .light .mdc-text-field--disabled .mdc-notched-outline__leading,
  body .light .mdc-text-field--disabled .mdc-notched-outline__notch,
  body .light .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
body.light .mdc-text-field--disabled.mdc-text-field--filled,
body .light .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] body.light .mdc-text-field--end-aligned .mdc-text-field__input, [dir=rtl] body .light .mdc-text-field--end-aligned .mdc-text-field__input, body.light .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl], body .light .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] body.light .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), [dir=rtl] body .light .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), body.light .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl], body .light .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
body.light .mat-mdc-form-field-error,
body .light .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field-focus-overlay,
body .light .mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}
body.light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
body .light .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}
body.light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
body .light .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}
body.light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
body .light .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
body.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
body .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(23, 23, 23, 0.87);
}
body.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
body .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(91, 33, 182, 0.87);
}
body.light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
body .light .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
body.light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
body .light .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-form-field.mat-accent .mdc-text-field__input,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #5b21b6);
}
body.light .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
body .light .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #5b21b6);
}
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(91, 33, 182, 0.87);
}
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body .light .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #5b21b6);
}
body.light .mat-mdc-form-field.mat-warn .mdc-text-field__input,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
body .light .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(239, 68, 68, 0.87);
}
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body .light .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
body .light .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
body.light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
body .light [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
body.light .mdc-menu-surface,
body .light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item__primary-text,
body .light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
body.light .mdc-list-item__secondary-text,
body .light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
body.light .mdc-list-item__overline-text,
body .light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
body.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
body.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
body.light .mdc-list-item__end,
body .light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
body.light .mdc-list-item--disabled .mdc-list-item__start,
body.light .mdc-list-item--disabled .mdc-list-item__content,
body.light .mdc-list-item--disabled .mdc-list-item__end,
body .light .mdc-list-item--disabled .mdc-list-item__start,
body .light .mdc-list-item--disabled .mdc-list-item__content,
body .light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
body.light .mdc-list-item--disabled .mdc-list-item__primary-text,
body .light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled .mdc-list-item__secondary-text,
body .light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled .mdc-list-item__overline-text,
body .light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
body .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--selected .mdc-list-item__primary-text,
body.light .mdc-list-item--activated .mdc-list-item__primary-text,
body .light .mdc-list-item--selected .mdc-list-item__primary-text,
body .light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #171717);
}
body.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #171717);
}
body.light .mdc-deprecated-list-group__subheader,
body .light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
body.light .mdc-list-divider::after,
body .light .mdc-list-divider::after {
  border-bottom-color: white;
}
body.light .mdc-list-divider,
body .light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
body.light .mat-mdc-select-value,
body .light .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}
body.light .mat-mdc-select-placeholder,
body .light .mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
body.light .mat-mdc-select-disabled .mat-mdc-select-value,
body .light .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-select-arrow,
body .light .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
body.light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
body .light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(23, 23, 23, 0.87);
}
body.light .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow,
body .light .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(91, 33, 182, 0.87);
}
body.light .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow,
body .light .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(239, 68, 68, 0.87);
}
body.light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
body .light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(239, 68, 68, 0.87);
}
body.light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
body .light .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mdc-menu-surface,
body .light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item__primary-text,
body .light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
body.light .mdc-list-item__secondary-text,
body .light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
body.light .mdc-list-item__overline-text,
body .light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
body.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
body.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
body.light .mdc-list-item__end,
body .light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
body.light .mdc-list-item--disabled .mdc-list-item__start,
body.light .mdc-list-item--disabled .mdc-list-item__content,
body.light .mdc-list-item--disabled .mdc-list-item__end,
body .light .mdc-list-item--disabled .mdc-list-item__start,
body .light .mdc-list-item--disabled .mdc-list-item__content,
body .light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
body.light .mdc-list-item--disabled .mdc-list-item__primary-text,
body .light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled .mdc-list-item__secondary-text,
body .light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled .mdc-list-item__overline-text,
body .light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
body .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--selected .mdc-list-item__primary-text,
body.light .mdc-list-item--activated .mdc-list-item__primary-text,
body .light .mdc-list-item--selected .mdc-list-item__primary-text,
body .light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #171717);
}
body.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #171717);
}
body.light .mdc-deprecated-list-group__subheader,
body .light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
body.light .mdc-list-divider::after,
body .light .mdc-list-divider::after {
  border-bottom-color: white;
}
body.light .mdc-list-divider,
body .light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
body.light .mat-mdc-dialog-container,
body .light .mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}
body.light .mat-mdc-standard-chip,
body .light .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
body.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, body.light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
body .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
body .light .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #171717;
  --mdc-chip-elevated-disabled-container-color: #171717;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
body.light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, body.light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted,
body .light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
body .light .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #5b21b6;
  --mdc-chip-elevated-disabled-container-color: #5b21b6;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
body.light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, body.light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted,
body .light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
body .light .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
body.light .mat-mdc-chip-focus-overlay,
body .light .mat-mdc-chip-focus-overlay {
  background: black;
}
body.light .mat-mdc-slide-toggle,
body .light .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
body.light .mat-mdc-slide-toggle .mdc-form-field,
body .light .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
body.light .mat-mdc-slide-toggle .mdc-switch--disabled + label,
body .light .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-slide-toggle.mat-primary,
body .light .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #141414;
  --mdc-switch-selected-handle-color: #141414;
  --mdc-switch-selected-hover-state-layer-color: #141414;
  --mdc-switch-selected-pressed-state-layer-color: #141414;
  --mdc-switch-selected-focus-handle-color: #070707;
  --mdc-switch-selected-hover-handle-color: #070707;
  --mdc-switch-selected-pressed-handle-color: #070707;
  --mdc-switch-selected-focus-track-color: #5d5d5d;
  --mdc-switch-selected-hover-track-color: #5d5d5d;
  --mdc-switch-selected-pressed-track-color: #5d5d5d;
  --mdc-switch-selected-track-color: #5d5d5d;
}
body.light .mat-mdc-slide-toggle.mat-accent,
body .light .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #531daf;
  --mdc-switch-selected-handle-color: #531daf;
  --mdc-switch-selected-hover-state-layer-color: #531daf;
  --mdc-switch-selected-pressed-state-layer-color: #531daf;
  --mdc-switch-selected-focus-handle-color: #2f0b8e;
  --mdc-switch-selected-hover-handle-color: #2f0b8e;
  --mdc-switch-selected-pressed-handle-color: #2f0b8e;
  --mdc-switch-selected-focus-track-color: #8c64cc;
  --mdc-switch-selected-hover-track-color: #8c64cc;
  --mdc-switch-selected-pressed-track-color: #8c64cc;
  --mdc-switch-selected-track-color: #8c64cc;
}
body.light .mat-mdc-slide-toggle.mat-warn,
body .light .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #ed3e3e;
  --mdc-switch-selected-handle-color: #ed3e3e;
  --mdc-switch-selected-hover-state-layer-color: #ed3e3e;
  --mdc-switch-selected-pressed-state-layer-color: #ed3e3e;
  --mdc-switch-selected-focus-handle-color: #e41f1f;
  --mdc-switch-selected-hover-handle-color: #e41f1f;
  --mdc-switch-selected-pressed-handle-color: #e41f1f;
  --mdc-switch-selected-focus-track-color: #f47c7c;
  --mdc-switch-selected-hover-track-color: #f47c7c;
  --mdc-switch-selected-pressed-track-color: #f47c7c;
  --mdc-switch-selected-track-color: #f47c7c;
}
body.light .mat-mdc-radio-button .mdc-form-field,
body .light .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
body.light .mat-mdc-radio-button.mat-primary,
body .light .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #171717;
  --mdc-radio-selected-hover-icon-color: #171717;
  --mdc-radio-selected-icon-color: #171717;
  --mdc-radio-selected-pressed-icon-color: #171717;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #171717;
}
body.light .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label,
body .light .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-radio-button.mat-accent,
body .light .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #5b21b6;
  --mdc-radio-selected-hover-icon-color: #5b21b6;
  --mdc-radio-selected-icon-color: #5b21b6;
  --mdc-radio-selected-pressed-icon-color: #5b21b6;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #5b21b6;
}
body.light .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label,
body .light .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-radio-button.mat-warn,
body .light .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #ef4444;
}
body.light .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label,
body .light .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-slider,
body .light .mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
body.light .mat-mdc-slider.mat-primary,
body .light .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #171717;
  --mdc-slider-focus-handle-color: #171717;
  --mdc-slider-hover-handle-color: #171717;
  --mdc-slider-active-track-color: #171717;
  --mdc-slider-inactive-track-color: #171717;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #171717;
  --mat-mdc-slider-ripple-color: #171717;
  --mat-mdc-slider-hover-ripple-color: rgba(23, 23, 23, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(23, 23, 23, 0.2);
}
body.light .mat-mdc-slider.mat-accent,
body .light .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #5b21b6;
  --mdc-slider-focus-handle-color: #5b21b6;
  --mdc-slider-hover-handle-color: #5b21b6;
  --mdc-slider-active-track-color: #5b21b6;
  --mdc-slider-inactive-track-color: #5b21b6;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #5b21b6;
  --mat-mdc-slider-ripple-color: #5b21b6;
  --mat-mdc-slider-hover-ripple-color: rgba(91, 33, 182, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(91, 33, 182, 0.2);
}
body.light .mat-mdc-slider.mat-warn,
body .light .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
body.light .mdc-menu-surface,
body .light .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item__primary-text,
body .light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
body.light .mdc-list-item__secondary-text,
body .light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
body.light .mdc-list-item__overline-text,
body .light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
body.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
body.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
body.light .mdc-list-item__end,
body .light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
body.light .mdc-list-item--disabled .mdc-list-item__start,
body.light .mdc-list-item--disabled .mdc-list-item__content,
body.light .mdc-list-item--disabled .mdc-list-item__end,
body .light .mdc-list-item--disabled .mdc-list-item__start,
body .light .mdc-list-item--disabled .mdc-list-item__content,
body .light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
body.light .mdc-list-item--disabled .mdc-list-item__primary-text,
body .light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled .mdc-list-item__secondary-text,
body .light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled .mdc-list-item__overline-text,
body .light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
body .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--selected .mdc-list-item__primary-text,
body.light .mdc-list-item--activated .mdc-list-item__primary-text,
body .light .mdc-list-item--selected .mdc-list-item__primary-text,
body .light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #171717);
}
body.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #171717);
}
body.light .mdc-deprecated-list-group__subheader,
body .light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
body.light .mdc-list-divider::after,
body .light .mdc-list-divider::after {
  border-bottom-color: white;
}
body.light .mdc-list-divider,
body .light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
body.light .mat-mdc-menu-item[disabled],
body.light .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
body.light .mat-mdc-menu-item[disabled] .mat-icon-no-color,
body .light .mat-mdc-menu-item[disabled],
body .light .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
body .light .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}
body.light .mat-mdc-menu-item .mat-icon-no-color,
body.light .mat-mdc-menu-submenu-icon,
body .light .mat-mdc-menu-item .mat-icon-no-color,
body .light .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
body.light .mat-mdc-menu-item:hover:not([disabled]),
body.light .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
body.light .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
body.light .mat-mdc-menu-item-highlighted:not([disabled]),
body .light .mat-mdc-menu-item:hover:not([disabled]),
body .light .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
body .light .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
body .light .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
body.light .mat-mdc-list-item-interactive::before,
body .light .mat-mdc-list-item-interactive::before {
  background: black;
}
body.light .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before,
body .light .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.04;
}
body.light .mat-mdc-list-item-interactive:focus::before,
body .light .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.12;
}
body.light .mat-mdc-list-item-interactive.mdc-list-item--selected::before,
body .light .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #171717;
  opacity: 0.08;
}
body.light .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before,
body .light .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
body.light .mdc-list-item__primary-text,
body .light .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
body.light .mdc-list-item__secondary-text,
body .light .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}
body.light .mdc-list-item__overline-text,
body .light .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
body.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
body.light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.light .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .light .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
body.light .mdc-list-item__end,
body .light .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}
body.light .mdc-list-item--disabled .mdc-list-item__start,
body.light .mdc-list-item--disabled .mdc-list-item__content,
body.light .mdc-list-item--disabled .mdc-list-item__end,
body .light .mdc-list-item--disabled .mdc-list-item__start,
body .light .mdc-list-item--disabled .mdc-list-item__content,
body .light .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
body.light .mdc-list-item--disabled .mdc-list-item__primary-text,
body .light .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled .mdc-list-item__secondary-text,
body .light .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled .mdc-list-item__overline-text,
body .light .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .light .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
body .light .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
body.light .mdc-list-item--selected .mdc-list-item__primary-text,
body.light .mdc-list-item--activated .mdc-list-item__primary-text,
body .light .mdc-list-item--selected .mdc-list-item__primary-text,
body .light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #171717);
}
body.light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body.light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .light .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #171717);
}
body.light .mdc-deprecated-list-group__subheader,
body .light .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
body.light .mdc-list-divider::after,
body .light .mdc-list-divider::after {
  border-bottom-color: white;
}
body.light .mdc-list-divider,
body .light .mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}
body.light .mat-mdc-list-option .mdc-list-item__start, body.light .mat-mdc-list-option .mdc-list-item__end,
body .light .mat-mdc-list-option .mdc-list-item__start,
body .light .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #171717;
  --mdc-checkbox-selected-hover-icon-color: #171717;
  --mdc-checkbox-selected-icon-color: #171717;
  --mdc-checkbox-selected-pressed-icon-color: #171717;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
body.light .mat-mdc-list-option .mdc-list-item__start, body.light .mat-mdc-list-option .mdc-list-item__end,
body .light .mat-mdc-list-option .mdc-list-item__start,
body .light .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #171717;
  --mdc-radio-selected-hover-icon-color: #171717;
  --mdc-radio-selected-icon-color: #171717;
  --mdc-radio-selected-pressed-icon-color: #171717;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #171717;
}
body.light .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, body.light .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label,
body .light .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label,
body .light .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-list-option.mat-accent .mdc-list-item__start, body.light .mat-mdc-list-option.mat-accent .mdc-list-item__end,
body .light .mat-mdc-list-option.mat-accent .mdc-list-item__start,
body .light .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #5b21b6;
  --mdc-checkbox-selected-hover-icon-color: #5b21b6;
  --mdc-checkbox-selected-icon-color: #5b21b6;
  --mdc-checkbox-selected-pressed-icon-color: #5b21b6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
body.light .mat-mdc-list-option.mat-accent .mdc-list-item__start, body.light .mat-mdc-list-option.mat-accent .mdc-list-item__end,
body .light .mat-mdc-list-option.mat-accent .mdc-list-item__start,
body .light .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #5b21b6;
  --mdc-radio-selected-hover-icon-color: #5b21b6;
  --mdc-radio-selected-icon-color: #5b21b6;
  --mdc-radio-selected-pressed-icon-color: #5b21b6;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #5b21b6;
}
body.light .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, body.light .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label,
body .light .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label,
body .light .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-list-option.mat-warn .mdc-list-item__start, body.light .mat-mdc-list-option.mat-warn .mdc-list-item__end,
body .light .mat-mdc-list-option.mat-warn .mdc-list-item__start,
body .light .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
body.light .mat-mdc-list-option.mat-warn .mdc-list-item__start, body.light .mat-mdc-list-option.mat-warn .mdc-list-item__end,
body .light .mat-mdc-list-option.mat-warn .mdc-list-item__start,
body .light .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #ef4444;
}
body.light .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, body.light .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label,
body .light .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label,
body .light .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-paginator,
body .light .mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
body.light .mat-mdc-paginator-icon,
body .light .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}
body.light .mat-mdc-paginator-decrement,
body.light .mat-mdc-paginator-increment,
body .light .mat-mdc-paginator-decrement,
body .light .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
body.light .mat-mdc-paginator-first,
body.light .mat-mdc-paginator-last,
body .light .mat-mdc-paginator-first,
body .light .mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
body.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
body.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
body.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
body.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last,
body .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
body .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
body .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
body .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
body.light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon,
body .light .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}
body.light .mat-mdc-tab, body.light .mat-mdc-tab-link,
body .light .mat-mdc-tab,
body .light .mat-mdc-tab-link {
  background-color: transparent;
}
body.light .mat-mdc-tab .mdc-tab__text-label, body.light .mat-mdc-tab-link .mdc-tab__text-label,
body .light .mat-mdc-tab .mdc-tab__text-label,
body .light .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
body.light .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
body.light .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, body.light .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
body.light .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element,
body .light .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
body .light .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element,
body .light .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
body .light .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .light .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #171717;
}
body.light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .light .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .light .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #171717);
}
body.light .mdc-tab__ripple::before,
body.light .mat-mdc-tab .mat-ripple-element,
body.light .mat-mdc-tab-header-pagination .mat-ripple-element,
body.light .mat-mdc-tab-link .mat-ripple-element,
body .light .mdc-tab__ripple::before,
body .light .mat-mdc-tab .mat-ripple-element,
body .light .mat-mdc-tab-header-pagination .mat-ripple-element,
body .light .mat-mdc-tab-link .mat-ripple-element {
  background-color: #171717;
}
body.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #5b21b6;
}
body.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #5b21b6);
}
body.light .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
body.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
body.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
body.light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, body.light .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
body.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
body.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
body.light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element,
body .light .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
body .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
body .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
body .light .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
body .light .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
body .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
body .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
body .light .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #5b21b6;
}
body.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #ef4444;
}
body.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #ef4444);
}
body.light .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
body.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
body.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
body.light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, body.light .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
body.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
body.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
body.light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element,
body .light .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
body .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
body .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
body .light .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
body .light .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
body .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
body .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
body .light .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #ef4444;
}
body.light .mat-mdc-tab-group.mat-background-primary, body.light .mat-mdc-tab-nav-bar.mat-background-primary,
body .light .mat-mdc-tab-group.mat-background-primary,
body .light .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #171717;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
body.light .mat-mdc-tab-group.mat-background-accent, body.light .mat-mdc-tab-nav-bar.mat-background-accent,
body .light .mat-mdc-tab-group.mat-background-accent,
body .light .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #5b21b6;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
body.light .mat-mdc-tab-group.mat-background-warn, body.light .mat-mdc-tab-nav-bar.mat-background-warn,
body .light .mat-mdc-tab-group.mat-background-warn,
body .light .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #ef4444;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
body.light .mat-mdc-tab-header-pagination-chevron,
body .light .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}
body.light .mat-mdc-checkbox .mdc-form-field,
body .light .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
body.light .mat-mdc-checkbox .mat-ripple-element,
body .light .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
body.light .mat-mdc-checkbox .mdc-checkbox__ripple,
body .light .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
body.light .mat-mdc-checkbox.mat-primary,
body .light .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #171717;
  --mdc-checkbox-selected-hover-icon-color: #171717;
  --mdc-checkbox-selected-icon-color: #171717;
  --mdc-checkbox-selected-pressed-icon-color: #171717;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
body.light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
body .light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(23, 23, 23, 0.1);
}
body.light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
body .light .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #171717;
}
body.light .mat-mdc-checkbox.mat-accent,
body .light .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #5b21b6;
  --mdc-checkbox-selected-hover-icon-color: #5b21b6;
  --mdc-checkbox-selected-icon-color: #5b21b6;
  --mdc-checkbox-selected-pressed-icon-color: #5b21b6;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
body.light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
body .light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(91, 33, 182, 0.1);
}
body.light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
body .light .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #5b21b6;
}
body.light .mat-mdc-checkbox.mat-warn,
body .light .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
body.light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
body .light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(239, 68, 68, 0.1);
}
body.light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
body .light .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #ef4444;
}
body.light .mat-mdc-checkbox-disabled label,
body .light .mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-button.mat-unthemed,
body .light .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
body.light .mat-mdc-button.mat-primary,
body .light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #171717;
}
body.light .mat-mdc-button.mat-accent,
body .light .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #5b21b6;
}
body.light .mat-mdc-button.mat-warn,
body .light .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ef4444;
}
body.light .mat-mdc-button[disabled][disabled],
body .light .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-unelevated-button.mat-unthemed,
body .light .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
body.light .mat-mdc-unelevated-button.mat-primary,
body .light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #171717;
  --mdc-filled-button-label-text-color: #fff;
}
body.light .mat-mdc-unelevated-button.mat-accent,
body .light .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #5b21b6;
  --mdc-filled-button-label-text-color: #fff;
}
body.light .mat-mdc-unelevated-button.mat-warn,
body .light .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
body.light .mat-mdc-unelevated-button[disabled][disabled],
body .light .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-raised-button.mat-unthemed,
body .light .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
body.light .mat-mdc-raised-button.mat-primary,
body .light .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #171717;
  --mdc-protected-button-label-text-color: #fff;
}
body.light .mat-mdc-raised-button.mat-accent,
body .light .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #5b21b6;
  --mdc-protected-button-label-text-color: #fff;
}
body.light .mat-mdc-raised-button.mat-warn,
body .light .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
body.light .mat-mdc-raised-button[disabled][disabled],
body .light .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
body.light .mat-mdc-outlined-button,
body .light .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
body.light .mat-mdc-outlined-button.mat-unthemed,
body .light .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
body.light .mat-mdc-outlined-button.mat-primary,
body .light .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #171717;
}
body.light .mat-mdc-outlined-button.mat-accent,
body .light .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #5b21b6;
}
body.light .mat-mdc-outlined-button.mat-warn,
body .light .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ef4444;
}
body.light .mat-mdc-outlined-button[disabled][disabled],
body .light .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
body.light .mat-mdc-button, body.light .mat-mdc-outlined-button,
body .light .mat-mdc-button,
body .light .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
body.light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
body.light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
body.light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
body.light .mat-mdc-button.mat-primary, body.light .mat-mdc-outlined-button.mat-primary,
body .light .mat-mdc-button.mat-primary,
body .light .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #171717;
  --mat-mdc-button-ripple-color: rgba(23, 23, 23, 0.1);
}
body.light .mat-mdc-button.mat-accent, body.light .mat-mdc-outlined-button.mat-accent,
body .light .mat-mdc-button.mat-accent,
body .light .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #5b21b6;
  --mat-mdc-button-ripple-color: rgba(91, 33, 182, 0.1);
}
body.light .mat-mdc-button.mat-warn, body.light .mat-mdc-outlined-button.mat-warn,
body .light .mat-mdc-button.mat-warn,
body .light .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
body.light .mat-mdc-raised-button, body.light .mat-mdc-unelevated-button,
body .light .mat-mdc-raised-button,
body .light .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
body.light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
body.light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
body.light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
body.light .mat-mdc-raised-button.mat-primary, body.light .mat-mdc-unelevated-button.mat-primary,
body .light .mat-mdc-raised-button.mat-primary,
body .light .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.light .mat-mdc-raised-button.mat-accent, body.light .mat-mdc-unelevated-button.mat-accent,
body .light .mat-mdc-raised-button.mat-accent,
body .light .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.light .mat-mdc-raised-button.mat-warn, body.light .mat-mdc-unelevated-button.mat-warn,
body .light .mat-mdc-raised-button.mat-warn,
body .light .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.light .mat-mdc-icon-button,
body .light .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
body.light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
body.light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
body.light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
body.light .mat-mdc-icon-button.mat-primary,
body .light .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #171717;
  --mat-mdc-button-ripple-color: rgba(23, 23, 23, 0.1);
}
body.light .mat-mdc-icon-button.mat-accent,
body .light .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #5b21b6;
  --mat-mdc-button-ripple-color: rgba(91, 33, 182, 0.1);
}
body.light .mat-mdc-icon-button.mat-warn,
body .light .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
body.light .mat-mdc-icon-button.mat-primary,
body .light .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #171717;
}
body.light .mat-mdc-icon-button.mat-accent,
body .light .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #5b21b6;
}
body.light .mat-mdc-icon-button.mat-warn,
body .light .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ef4444;
}
body.light .mat-mdc-icon-button[disabled][disabled],
body .light .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-fab, body.light .mat-mdc-mini-fab,
body .light .mat-mdc-fab,
body .light .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
body.light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
body.light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
body.light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, body.light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
body .light .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
body.light .mat-mdc-fab.mat-primary, body.light .mat-mdc-mini-fab.mat-primary,
body .light .mat-mdc-fab.mat-primary,
body .light .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.light .mat-mdc-fab.mat-accent, body.light .mat-mdc-mini-fab.mat-accent,
body .light .mat-mdc-fab.mat-accent,
body .light .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.light .mat-mdc-fab.mat-warn, body.light .mat-mdc-mini-fab.mat-warn,
body .light .mat-mdc-fab.mat-warn,
body .light .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.light .mat-mdc-fab.mat-unthemed, body.light .mat-mdc-mini-fab.mat-unthemed,
body .light .mat-mdc-fab.mat-unthemed,
body .light .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
body.light .mat-mdc-fab.mat-primary, body.light .mat-mdc-mini-fab.mat-primary,
body .light .mat-mdc-fab.mat-primary,
body .light .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #171717;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
body.light .mat-mdc-fab.mat-accent, body.light .mat-mdc-mini-fab.mat-accent,
body .light .mat-mdc-fab.mat-accent,
body .light .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #5b21b6;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
body.light .mat-mdc-fab.mat-warn, body.light .mat-mdc-mini-fab.mat-warn,
body .light .mat-mdc-fab.mat-warn,
body .light .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
body.light .mat-mdc-fab[disabled][disabled], body.light .mat-mdc-mini-fab[disabled][disabled],
body .light .mat-mdc-fab[disabled][disabled],
body .light .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
body.light .mat-mdc-snack-bar-container,
body .light .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #5b21b6;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}
body.light .mdc-data-table,
body .light .mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
body.light .mdc-data-table__row,
body .light .mdc-data-table__row {
  background-color: inherit;
}
body.light .mdc-data-table__header-cell,
body .light .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}
body.light .mdc-data-table__row--selected,
body .light .mdc-data-table__row--selected {
  background-color: rgba(23, 23, 23, 0.04);
}
body.light .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
body.light .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
body.light .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
body .light .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
body .light .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
body .light .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
body.light .mdc-data-table__cell,
body.light .mdc-data-table__header-cell,
body .light .mdc-data-table__cell,
body .light .mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
body.light .mdc-data-table__pagination,
body .light .mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}
body.light .mdc-data-table__row:not(.mdc-data-table__row--selected):hover,
body .light .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
body.light .mdc-data-table__header-cell,
body .light .mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}
body.light .mdc-data-table__pagination-total,
body.light .mdc-data-table__pagination-rows-per-page-label,
body.light .mdc-data-table__cell,
body .light .mdc-data-table__pagination-total,
body .light .mdc-data-table__pagination-rows-per-page-label,
body .light .mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}
[dir=rtl] body.light .mdc-data-table__pagination-button .mdc-button__icon, [dir=rtl] body .light .mdc-data-table__pagination-button .mdc-button__icon, body.light .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl], body .light .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

body.light .mat-mdc-table,
body .light .mat-mdc-table {
  background: white;
}
body.light .mat-mdc-progress-spinner,
body .light .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #171717;
}
body.light .mat-mdc-progress-spinner.mat-accent,
body .light .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #5b21b6;
}
body.light .mat-mdc-progress-spinner.mat-warn,
body .light .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
body.light .mat-badge-content,
body .light .mat-badge-content {
  color: white;
  background: #171717;
}
.cdk-high-contrast-active body.light .mat-badge-content, .cdk-high-contrast-active body .light .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

body.light .mat-badge-accent .mat-badge-content,
body .light .mat-badge-accent .mat-badge-content {
  background: #5b21b6;
  color: white;
}
body.light .mat-badge-warn .mat-badge-content,
body .light .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ef4444;
}
body.light .mat-badge-disabled .mat-badge-content,
body .light .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-bottom-sheet-container,
body .light .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
body.light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
body.light .mat-button-toggle-group:not([class*=mat-elevation-z]),
body .light .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
body .light .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
body.light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
body .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
body .light .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
body.light .mat-button-toggle,
body .light .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-button-toggle .mat-button-toggle-focus-overlay,
body .light .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
body.light .mat-button-toggle-appearance-standard,
body .light .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
body.light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
body .light .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
body.light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
body .light .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
body.light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
body .light [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
body.light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
body .light .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
body.light .mat-button-toggle-checked,
body .light .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
body.light .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
body .light .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
body.light .mat-button-toggle-disabled,
body .light .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
body.light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
body .light .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
body.light .mat-button-toggle-disabled.mat-button-toggle-checked,
body .light .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
body.light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
body.light .mat-button-toggle-group-appearance-standard,
body .light .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
body .light .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
body.light .mat-calendar-arrow,
body .light .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
body.light .mat-datepicker-toggle,
body.light .mat-datepicker-content .mat-calendar-next-button,
body.light .mat-datepicker-content .mat-calendar-previous-button,
body .light .mat-datepicker-toggle,
body .light .mat-datepicker-content .mat-calendar-next-button,
body .light .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
body.light .mat-calendar-table-header-divider::after,
body .light .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
body.light .mat-calendar-table-header,
body.light .mat-calendar-body-label,
body .light .mat-calendar-table-header,
body .light .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
body.light .mat-calendar-body-cell-content,
body.light .mat-date-range-input-separator,
body .light .mat-calendar-body-cell-content,
body .light .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
body.light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-form-field-disabled .mat-date-range-input-separator,
body .light .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-calendar-body-in-preview,
body .light .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
body.light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
body.light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
body.light .mat-calendar-body-in-range::before,
body .light .mat-calendar-body-in-range::before {
  background: rgba(23, 23, 23, 0.2);
}
body.light .mat-calendar-body-comparison-identical,
body.light .mat-calendar-body-in-comparison-range::before,
body .light .mat-calendar-body-comparison-identical,
body .light .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.light .mat-calendar-body-comparison-bridge-start::before,
body.light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
body .light .mat-calendar-body-comparison-bridge-start::before,
body .light [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(23, 23, 23, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.light .mat-calendar-body-comparison-bridge-end::before,
body.light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
body .light .mat-calendar-body-comparison-bridge-end::before,
body .light [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(23, 23, 23, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
body .light .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body .light .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
body .light .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body .light .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.light .mat-calendar-body-selected,
body .light .mat-calendar-body-selected {
  background-color: #171717;
  color: white;
}
body.light .mat-calendar-body-disabled > .mat-calendar-body-selected,
body .light .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(23, 23, 23, 0.4);
}
body.light .mat-calendar-body-today.mat-calendar-body-selected,
body .light .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
body.light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(23, 23, 23, 0.3);
}
@media (hover: hover) {
  body.light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  body .light .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(23, 23, 23, 0.3);
  }
}
body.light .mat-datepicker-content,
body .light .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(91, 33, 182, 0.2);
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
body.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
body .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(91, 33, 182, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
body.light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
body .light .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(91, 33, 182, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #5b21b6;
  color: white;
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(91, 33, 182, 0.4);
}
body.light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
body.light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(91, 33, 182, 0.3);
}
@media (hover: hover) {
  body.light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  body .light .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(91, 33, 182, 0.3);
  }
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(239, 68, 68, 0.2);
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
body.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
body .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(239, 68, 68, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
body.light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
body .light .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(239, 68, 68, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ef4444;
  color: white;
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(239, 68, 68, 0.4);
}
body.light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
body .light .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
body.light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .light .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(239, 68, 68, 0.3);
}
@media (hover: hover) {
  body.light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  body .light .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(239, 68, 68, 0.3);
  }
}
body.light .mat-datepicker-content-touch,
body .light .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
body.light .mat-datepicker-toggle-active,
body .light .mat-datepicker-toggle-active {
  color: #171717;
}
body.light .mat-datepicker-toggle-active.mat-accent,
body .light .mat-datepicker-toggle-active.mat-accent {
  color: #5b21b6;
}
body.light .mat-datepicker-toggle-active.mat-warn,
body .light .mat-datepicker-toggle-active.mat-warn {
  color: #ef4444;
}
body.light .mat-date-range-input-inner[disabled],
body .light .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
body.light .mat-divider,
body .light .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
body.light .mat-divider-vertical,
body .light .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
body.light .mat-expansion-panel,
body .light .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
body.light .mat-expansion-panel:not([class*=mat-elevation-z]),
body .light .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.light .mat-action-row,
body .light .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
body.light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), body.light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), body.light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
body .light .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
body .light .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
body .light .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  body.light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
  body .light .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
body.light .mat-expansion-panel-header-title,
body .light .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
body.light .mat-expansion-panel-header-description,
body.light .mat-expansion-indicator::after,
body .light .mat-expansion-panel-header-description,
body .light .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
body.light .mat-expansion-panel-header[aria-disabled=true],
body .light .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
body.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
body.light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
body .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
body .light .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
body.light .mat-icon.mat-primary,
body .light .mat-icon.mat-primary {
  color: #171717;
}
body.light .mat-icon.mat-accent,
body .light .mat-icon.mat-accent {
  color: #5b21b6;
}
body.light .mat-icon.mat-warn,
body .light .mat-icon.mat-warn {
  color: #ef4444;
}
body.light .mat-drawer-container,
body .light .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
body.light .mat-drawer,
body .light .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
body.light .mat-drawer.mat-drawer-push,
body .light .mat-drawer.mat-drawer-push {
  background-color: white;
}
body.light .mat-drawer:not(.mat-drawer-side),
body .light .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
body.light .mat-drawer-side,
body .light .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
body.light .mat-drawer-side.mat-drawer-end,
body .light .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
body.light [dir=rtl] .mat-drawer-side,
body .light [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
body.light [dir=rtl] .mat-drawer-side.mat-drawer-end,
body .light [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
body.light .mat-drawer-backdrop.mat-drawer-shown,
body .light .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
body.light .mat-step-header.cdk-keyboard-focused, body.light .mat-step-header.cdk-program-focused, body.light .mat-step-header:hover:not([aria-disabled]), body.light .mat-step-header:hover[aria-disabled=false],
body .light .mat-step-header.cdk-keyboard-focused,
body .light .mat-step-header.cdk-program-focused,
body .light .mat-step-header:hover:not([aria-disabled]),
body .light .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
body.light .mat-step-header:hover[aria-disabled=true],
body .light .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  body.light .mat-step-header:hover,
  body .light .mat-step-header:hover {
    background: none;
  }
}
body.light .mat-step-header .mat-step-label,
body.light .mat-step-header .mat-step-optional,
body .light .mat-step-header .mat-step-label,
body .light .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
body.light .mat-step-header .mat-step-icon,
body .light .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
body.light .mat-step-header .mat-step-icon-selected,
body.light .mat-step-header .mat-step-icon-state-done,
body.light .mat-step-header .mat-step-icon-state-edit,
body .light .mat-step-header .mat-step-icon-selected,
body .light .mat-step-header .mat-step-icon-state-done,
body .light .mat-step-header .mat-step-icon-state-edit {
  background-color: #171717;
  color: white;
}
body.light .mat-step-header.mat-accent .mat-step-icon,
body .light .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
body.light .mat-step-header.mat-accent .mat-step-icon-selected,
body.light .mat-step-header.mat-accent .mat-step-icon-state-done,
body.light .mat-step-header.mat-accent .mat-step-icon-state-edit,
body .light .mat-step-header.mat-accent .mat-step-icon-selected,
body .light .mat-step-header.mat-accent .mat-step-icon-state-done,
body .light .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #5b21b6;
  color: white;
}
body.light .mat-step-header.mat-warn .mat-step-icon,
body .light .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
body.light .mat-step-header.mat-warn .mat-step-icon-selected,
body.light .mat-step-header.mat-warn .mat-step-icon-state-done,
body.light .mat-step-header.mat-warn .mat-step-icon-state-edit,
body .light .mat-step-header.mat-warn .mat-step-icon-selected,
body .light .mat-step-header.mat-warn .mat-step-icon-state-done,
body .light .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ef4444;
  color: white;
}
body.light .mat-step-header .mat-step-icon-state-error,
body .light .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ef4444;
}
body.light .mat-step-header .mat-step-label.mat-step-label-active,
body .light .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
body.light .mat-step-header .mat-step-label.mat-step-label-error,
body .light .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ef4444;
}
body.light .mat-stepper-horizontal, body.light .mat-stepper-vertical,
body .light .mat-stepper-horizontal,
body .light .mat-stepper-vertical {
  background-color: white;
}
body.light .mat-stepper-vertical-line::before,
body .light .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
body.light .mat-horizontal-stepper-header::before,
body.light .mat-horizontal-stepper-header::after,
body.light .mat-stepper-horizontal-line,
body .light .mat-horizontal-stepper-header::before,
body .light .mat-horizontal-stepper-header::after,
body .light .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
body.light .mat-sort-header-arrow,
body .light .mat-sort-header-arrow {
  color: #757575;
}
body.light .mat-toolbar,
body .light .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
body.light .mat-toolbar.mat-primary,
body .light .mat-toolbar.mat-primary {
  background: #171717;
  color: white;
}
body.light .mat-toolbar.mat-accent,
body .light .mat-toolbar.mat-accent {
  background: #5b21b6;
  color: white;
}
body.light .mat-toolbar.mat-warn,
body .light .mat-toolbar.mat-warn {
  background: #ef4444;
  color: white;
}
body.light .mat-toolbar .mat-form-field-underline,
body.light .mat-toolbar .mat-form-field-ripple,
body.light .mat-toolbar .mat-focused .mat-form-field-ripple,
body .light .mat-toolbar .mat-form-field-underline,
body .light .mat-toolbar .mat-form-field-ripple,
body .light .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
body.light .mat-toolbar .mat-form-field-label,
body.light .mat-toolbar .mat-focused .mat-form-field-label,
body.light .mat-toolbar .mat-select-value,
body.light .mat-toolbar .mat-select-arrow,
body.light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
body .light .mat-toolbar .mat-form-field-label,
body .light .mat-toolbar .mat-focused .mat-form-field-label,
body .light .mat-toolbar .mat-select-value,
body .light .mat-toolbar .mat-select-arrow,
body .light .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
body.light .mat-toolbar .mat-input-element,
body .light .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
body.light .mat-tree,
body .light .mat-tree {
  background: white;
}
body.light .mat-tree-node,
body.light .mat-nested-tree-node,
body .light .mat-tree-node,
body .light .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

body.dark .mat-ripple-element,
body .dark .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
body.dark .mat-mdc-option,
body .dark .mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark .mat-mdc-option:hover:not(.mdc-list-item--disabled), body.dark .mat-mdc-option:focus:not(.mdc-list-item--disabled), body.dark .mat-mdc-option.mat-mdc-option-active, body.dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled),
body .dark .mat-mdc-option:hover:not(.mdc-list-item--disabled),
body .dark .mat-mdc-option:focus:not(.mdc-list-item--disabled),
body .dark .mat-mdc-option.mat-mdc-option-active,
body .dark .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(255, 255, 255, 0.08);
}
body.dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
body .dark .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #171717);
}
body.dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
body .dark .mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #5b21b6);
}
body.dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
body .dark .mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-optgroup-label,
body .dark .mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark .mat-pseudo-checkbox,
body .dark .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
body.dark .mat-pseudo-checkbox::after,
body .dark .mat-pseudo-checkbox::after {
  color: #303030;
}
body.dark .mat-pseudo-checkbox-disabled,
body .dark .mat-pseudo-checkbox-disabled {
  color: #686868;
}
body.dark .mat-primary .mat-pseudo-checkbox-checked,
body.dark .mat-primary .mat-pseudo-checkbox-indeterminate,
body .dark .mat-primary .mat-pseudo-checkbox-checked,
body .dark .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #171717;
}
body.dark .mat-pseudo-checkbox-checked,
body.dark .mat-pseudo-checkbox-indeterminate,
body.dark .mat-accent .mat-pseudo-checkbox-checked,
body.dark .mat-accent .mat-pseudo-checkbox-indeterminate,
body .dark .mat-pseudo-checkbox-checked,
body .dark .mat-pseudo-checkbox-indeterminate,
body .dark .mat-accent .mat-pseudo-checkbox-checked,
body .dark .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #5b21b6;
}
body.dark .mat-warn .mat-pseudo-checkbox-checked,
body.dark .mat-warn .mat-pseudo-checkbox-indeterminate,
body .dark .mat-warn .mat-pseudo-checkbox-checked,
body .dark .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ef4444;
}
body.dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
body.dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled,
body .dark .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
body .dark .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
body.dark .mat-app-background, body.dark.mat-app-background,
body .dark .mat-app-background,
body .dark.mat-app-background {
  background-color: #303030;
  color: white;
}
body.dark .mat-elevation-z0, body.dark .mat-mdc-elevation-specific.mat-elevation-z0,
body .dark .mat-elevation-z0,
body .dark .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z1, body.dark .mat-mdc-elevation-specific.mat-elevation-z1,
body .dark .mat-elevation-z1,
body .dark .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z2, body.dark .mat-mdc-elevation-specific.mat-elevation-z2,
body .dark .mat-elevation-z2,
body .dark .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z3, body.dark .mat-mdc-elevation-specific.mat-elevation-z3,
body .dark .mat-elevation-z3,
body .dark .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z4, body.dark .mat-mdc-elevation-specific.mat-elevation-z4,
body .dark .mat-elevation-z4,
body .dark .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z5, body.dark .mat-mdc-elevation-specific.mat-elevation-z5,
body .dark .mat-elevation-z5,
body .dark .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z6, body.dark .mat-mdc-elevation-specific.mat-elevation-z6,
body .dark .mat-elevation-z6,
body .dark .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z7, body.dark .mat-mdc-elevation-specific.mat-elevation-z7,
body .dark .mat-elevation-z7,
body .dark .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z8, body.dark .mat-mdc-elevation-specific.mat-elevation-z8,
body .dark .mat-elevation-z8,
body .dark .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z9, body.dark .mat-mdc-elevation-specific.mat-elevation-z9,
body .dark .mat-elevation-z9,
body .dark .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z10, body.dark .mat-mdc-elevation-specific.mat-elevation-z10,
body .dark .mat-elevation-z10,
body .dark .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z11, body.dark .mat-mdc-elevation-specific.mat-elevation-z11,
body .dark .mat-elevation-z11,
body .dark .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z12, body.dark .mat-mdc-elevation-specific.mat-elevation-z12,
body .dark .mat-elevation-z12,
body .dark .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z13, body.dark .mat-mdc-elevation-specific.mat-elevation-z13,
body .dark .mat-elevation-z13,
body .dark .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z14, body.dark .mat-mdc-elevation-specific.mat-elevation-z14,
body .dark .mat-elevation-z14,
body .dark .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z15, body.dark .mat-mdc-elevation-specific.mat-elevation-z15,
body .dark .mat-elevation-z15,
body .dark .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z16, body.dark .mat-mdc-elevation-specific.mat-elevation-z16,
body .dark .mat-elevation-z16,
body .dark .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z17, body.dark .mat-mdc-elevation-specific.mat-elevation-z17,
body .dark .mat-elevation-z17,
body .dark .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z18, body.dark .mat-mdc-elevation-specific.mat-elevation-z18,
body .dark .mat-elevation-z18,
body .dark .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z19, body.dark .mat-mdc-elevation-specific.mat-elevation-z19,
body .dark .mat-elevation-z19,
body .dark .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z20, body.dark .mat-mdc-elevation-specific.mat-elevation-z20,
body .dark .mat-elevation-z20,
body .dark .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z21, body.dark .mat-mdc-elevation-specific.mat-elevation-z21,
body .dark .mat-elevation-z21,
body .dark .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z22, body.dark .mat-mdc-elevation-specific.mat-elevation-z22,
body .dark .mat-elevation-z22,
body .dark .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z23, body.dark .mat-mdc-elevation-specific.mat-elevation-z23,
body .dark .mat-elevation-z23,
body .dark .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
body.dark .mat-elevation-z24, body.dark .mat-mdc-elevation-specific.mat-elevation-z24,
body .dark .mat-elevation-z24,
body .dark .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

body.dark .mat-mdc-card,
body .dark .mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #424242;
}
body.dark .mat-mdc-card-outlined,
body .dark .mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #595959;
}
body.dark .mat-mdc-card-subtitle,
body .dark .mat-mdc-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
body.dark .mat-mdc-progress-bar,
body .dark .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #171717;
}
body.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots,
body .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(23, 23, 23, 0.25)'/%3E%3C/svg%3E");
}
body.dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar,
body .dark .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(23, 23, 23, 0.25);
}
body.dark .mat-mdc-progress-bar.mat-accent,
body .dark .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #5b21b6;
}
body.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots,
body .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(91, 33, 182, 0.25)'/%3E%3C/svg%3E");
}
body.dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar,
body .dark .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(91, 33, 182, 0.25);
}
body.dark .mat-mdc-progress-bar.mat-warn,
body .dark .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ef4444;
}
body.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots,
body .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(239, 68, 68, 0.25)'/%3E%3C/svg%3E");
}
body.dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar,
body .dark .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(239, 68, 68, 0.25);
}
body.dark .mat-mdc-tooltip,
body .dark .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}
body.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
body .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6);
}
body.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
body .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.87);
}
@media all {
  body.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
  body .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
@media all {
  body.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder,
  body .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}
body.dark .mdc-text-field .mdc-text-field__input,
body .dark .mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #171717);
}
body.dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text,
body .dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
body.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
body.dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter,
body .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
body .dark .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
body.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
body .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
body.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
body .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
body.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix,
body .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
body.dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix,
body .dark .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
body.dark .mdc-text-field--filled .mdc-text-field__ripple::before,
body.dark .mdc-text-field--filled .mdc-text-field__ripple::after,
body .dark .mdc-text-field--filled .mdc-text-field__ripple::before,
body .dark .mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
body.dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before, body.dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before,
body .dark .mdc-text-field--filled:hover .mdc-text-field__ripple::before,
body .dark .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
body.dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, body.dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before,
body .dark .mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
body .dark .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
body.dark .mdc-text-field--filled:not(.mdc-text-field--disabled),
body .dark .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #4a4a4a;
}
body.dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
body .dark .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.42);
}
body.dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
body .dark .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.87);
}
body.dark .mdc-text-field--filled .mdc-line-ripple::after,
body .dark .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #171717);
}
body.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
body .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.38);
}
body.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
body .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.87);
}
body.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
body .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body .dark .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #171717);
}
[dir=rtl] body.dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, [dir=rtl] body .dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon, body.dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl], body .dark .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] body.dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, [dir=rtl] body .dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, body.dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl], body .dark .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

body.dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
body.dark .mdc-text-field--outlined .mdc-text-field__ripple::after,
body .dark .mdc-text-field--outlined .mdc-text-field__ripple::before,
body .dark .mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] body.dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, [dir=rtl] body .dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined, body.dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl], body .dark .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
body.dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
body .dark .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(23, 23, 23, 0.87);
}
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ef4444);
}
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ef4444);
}
body.dark .mdc-text-field--invalid .mdc-text-field__input,
body .dark .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ef4444);
}
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body .dark .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mdc-text-field--disabled .mdc-text-field__input,
body .dark .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.38);
}
@media all {
  body.dark .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  body .dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
@media all {
  body.dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  body .dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.38);
  }
}
body.dark .mdc-text-field--disabled .mdc-floating-label,
body .dark .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
body.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
body .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
body.dark .mdc-text-field--disabled .mdc-text-field-character-counter,
body.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
body .dark .mdc-text-field--disabled .mdc-text-field-character-counter,
body .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
body.dark .mdc-text-field--disabled .mdc-text-field__icon--leading,
body .dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
body.dark .mdc-text-field--disabled .mdc-text-field__icon--trailing,
body .dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
body.dark .mdc-text-field--disabled .mdc-text-field__affix--prefix,
body .dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
body.dark .mdc-text-field--disabled .mdc-text-field__affix--suffix,
body .dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
body.dark .mdc-text-field--disabled .mdc-line-ripple::before,
body .dark .mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(255, 255, 255, 0.06);
}
body.dark .mdc-text-field--disabled .mdc-notched-outline__leading,
body.dark .mdc-text-field--disabled .mdc-notched-outline__notch,
body.dark .mdc-text-field--disabled .mdc-notched-outline__trailing,
body .dark .mdc-text-field--disabled .mdc-notched-outline__leading,
body .dark .mdc-text-field--disabled .mdc-notched-outline__notch,
body .dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark .mdc-text-field--disabled .mdc-text-field__input::placeholder,
  body .dark .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder,
  body .dark .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark .mdc-text-field--disabled .mdc-floating-label,
  body .dark .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text,
  body .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  body.dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter,
  body .dark .mdc-text-field--disabled .mdc-text-field-character-counter,
  body .dark .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark .mdc-text-field--disabled .mdc-text-field__icon--leading,
  body .dark .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark .mdc-text-field--disabled .mdc-text-field__icon--trailing,
  body .dark .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark .mdc-text-field--disabled .mdc-text-field__affix--prefix,
  body .dark .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark .mdc-text-field--disabled .mdc-text-field__affix--suffix,
  body .dark .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark .mdc-text-field--disabled .mdc-line-ripple::before,
  body .dark .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  body.dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  body.dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  body.dark .mdc-text-field--disabled .mdc-notched-outline__trailing,
  body .dark .mdc-text-field--disabled .mdc-notched-outline__leading,
  body .dark .mdc-text-field--disabled .mdc-notched-outline__notch,
  body .dark .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
body.dark .mdc-text-field--disabled.mdc-text-field--filled,
body .dark .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #464646;
}
[dir=rtl] body.dark .mdc-text-field--end-aligned .mdc-text-field__input, [dir=rtl] body .dark .mdc-text-field--end-aligned .mdc-text-field__input, body.dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl], body .dark .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] body.dark .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), [dir=rtl] body .dark .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), body.dark .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl], body .dark .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
body.dark .mat-mdc-form-field-error,
body .dark .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field-focus-overlay,
body .dark .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
body.dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
body .dark .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
body.dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
body .dark .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
body.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option,
body .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
body.dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled,
body .dark select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
body.dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after,
body .dark .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
body.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after,
body .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(23, 23, 23, 0.87);
}
body.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after,
body .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(91, 33, 182, 0.87);
}
body.dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after,
body .dark .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(239, 68, 68, 0.87);
}
body.dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after,
body .dark .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field__input,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #5b21b6);
}
body.dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
body .dark .mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #5b21b6);
}
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(91, 33, 182, 0.87);
}
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body .dark .mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #5b21b6);
}
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field__input,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
body .dark .mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(239, 68, 68, 0.87);
}
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body.dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
body .dark .mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #ef4444);
}
body.dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
body .dark .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
body.dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch,
body .dark [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
body.dark .mdc-menu-surface,
body .dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #424242);
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item__primary-text,
body .dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark .mdc-list-item__secondary-text,
body .dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
body.dark .mdc-list-item__overline-text,
body .dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
body.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
body.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
body.dark .mdc-list-item__end,
body .dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
body.dark .mdc-list-item--disabled .mdc-list-item__start,
body.dark .mdc-list-item--disabled .mdc-list-item__content,
body.dark .mdc-list-item--disabled .mdc-list-item__end,
body .dark .mdc-list-item--disabled .mdc-list-item__start,
body .dark .mdc-list-item--disabled .mdc-list-item__content,
body .dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
body.dark .mdc-list-item--disabled .mdc-list-item__primary-text,
body .dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
body .dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled .mdc-list-item__overline-text,
body .dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
body .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--selected .mdc-list-item__primary-text,
body.dark .mdc-list-item--activated .mdc-list-item__primary-text,
body .dark .mdc-list-item--selected .mdc-list-item__primary-text,
body .dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #171717);
}
body.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #171717);
}
body.dark .mdc-deprecated-list-group__subheader,
body .dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark .mdc-list-divider::after,
body .dark .mdc-list-divider::after {
  border-bottom-color: white;
}
body.dark .mdc-list-divider,
body .dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
body.dark .mat-mdc-select-value,
body .dark .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87);
}
body.dark .mat-mdc-select-placeholder,
body .dark .mat-mdc-select-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
body.dark .mat-mdc-select-disabled .mat-mdc-select-value,
body .dark .mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.38);
}
body.dark .mat-mdc-select-arrow,
body .dark .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
body.dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
body .dark .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(23, 23, 23, 0.87);
}
body.dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow,
body .dark .mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(91, 33, 182, 0.87);
}
body.dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow,
body .dark .mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(239, 68, 68, 0.87);
}
body.dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
body .dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(239, 68, 68, 0.87);
}
body.dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow,
body .dark .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.38);
}
body.dark .mdc-menu-surface,
body .dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #424242);
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item__primary-text,
body .dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark .mdc-list-item__secondary-text,
body .dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
body.dark .mdc-list-item__overline-text,
body .dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
body.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
body.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
body.dark .mdc-list-item__end,
body .dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
body.dark .mdc-list-item--disabled .mdc-list-item__start,
body.dark .mdc-list-item--disabled .mdc-list-item__content,
body.dark .mdc-list-item--disabled .mdc-list-item__end,
body .dark .mdc-list-item--disabled .mdc-list-item__start,
body .dark .mdc-list-item--disabled .mdc-list-item__content,
body .dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
body.dark .mdc-list-item--disabled .mdc-list-item__primary-text,
body .dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
body .dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled .mdc-list-item__overline-text,
body .dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
body .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--selected .mdc-list-item__primary-text,
body.dark .mdc-list-item--activated .mdc-list-item__primary-text,
body .dark .mdc-list-item--selected .mdc-list-item__primary-text,
body .dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #171717);
}
body.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #171717);
}
body.dark .mdc-deprecated-list-group__subheader,
body .dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark .mdc-list-divider::after,
body .dark .mdc-list-divider::after {
  border-bottom-color: white;
}
body.dark .mdc-list-divider,
body .dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
body.dark .mat-mdc-dialog-container,
body .dark .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-with-divider-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
body.dark .mat-mdc-standard-chip,
body .dark .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #595959;
  --mdc-chip-elevated-disabled-container-color: #595959;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
}
body.dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, body.dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
body .dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
body .dark .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #171717;
  --mdc-chip-elevated-disabled-container-color: #171717;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
body.dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, body.dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted,
body .dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
body .dark .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #5b21b6;
  --mdc-chip-elevated-disabled-container-color: #5b21b6;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
body.dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, body.dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted,
body .dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
body .dark .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #ef4444;
  --mdc-chip-elevated-disabled-container-color: #ef4444;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
body.dark .mat-mdc-chip-focus-overlay,
body .dark .mat-mdc-chip-focus-overlay {
  background: white;
}
body.dark .mat-mdc-slide-toggle,
body .dark .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-unselected-icon-color: #212121;
}
body.dark .mat-mdc-slide-toggle .mdc-form-field,
body .dark .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark .mat-mdc-slide-toggle .mdc-switch--disabled + label,
body .dark .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-mdc-slide-toggle.mat-primary,
body .dark .mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #5d5d5d;
  --mdc-switch-selected-handle-color: #5d5d5d;
  --mdc-switch-selected-hover-state-layer-color: #5d5d5d;
  --mdc-switch-selected-pressed-state-layer-color: #5d5d5d;
  --mdc-switch-selected-focus-handle-color: #8b8b8b;
  --mdc-switch-selected-hover-handle-color: #8b8b8b;
  --mdc-switch-selected-pressed-handle-color: #8b8b8b;
  --mdc-switch-selected-focus-track-color: #141414;
  --mdc-switch-selected-hover-track-color: #141414;
  --mdc-switch-selected-pressed-track-color: #141414;
  --mdc-switch-selected-track-color: #141414;
}
body.dark .mat-mdc-slide-toggle.mat-accent,
body .dark .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #8c64cc;
  --mdc-switch-selected-handle-color: #8c64cc;
  --mdc-switch-selected-hover-state-layer-color: #8c64cc;
  --mdc-switch-selected-pressed-state-layer-color: #8c64cc;
  --mdc-switch-selected-focus-handle-color: #ad90db;
  --mdc-switch-selected-hover-handle-color: #ad90db;
  --mdc-switch-selected-pressed-handle-color: #ad90db;
  --mdc-switch-selected-focus-track-color: #531daf;
  --mdc-switch-selected-hover-track-color: #531daf;
  --mdc-switch-selected-pressed-track-color: #531daf;
  --mdc-switch-selected-track-color: #531daf;
}
body.dark .mat-mdc-slide-toggle.mat-warn,
body .dark .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #f47c7c;
  --mdc-switch-selected-handle-color: #f47c7c;
  --mdc-switch-selected-hover-state-layer-color: #f47c7c;
  --mdc-switch-selected-pressed-state-layer-color: #f47c7c;
  --mdc-switch-selected-focus-handle-color: #f7a2a2;
  --mdc-switch-selected-hover-handle-color: #f7a2a2;
  --mdc-switch-selected-pressed-handle-color: #f7a2a2;
  --mdc-switch-selected-focus-track-color: #ed3e3e;
  --mdc-switch-selected-hover-track-color: #ed3e3e;
  --mdc-switch-selected-pressed-track-color: #ed3e3e;
  --mdc-switch-selected-track-color: #ed3e3e;
}
body.dark .mat-mdc-radio-button .mdc-form-field,
body .dark .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark .mat-mdc-radio-button.mat-primary,
body .dark .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #171717;
  --mdc-radio-selected-hover-icon-color: #171717;
  --mdc-radio-selected-icon-color: #171717;
  --mdc-radio-selected-pressed-icon-color: #171717;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #171717;
}
body.dark .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label,
body .dark .mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-mdc-radio-button.mat-accent,
body .dark .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #5b21b6;
  --mdc-radio-selected-hover-icon-color: #5b21b6;
  --mdc-radio-selected-icon-color: #5b21b6;
  --mdc-radio-selected-pressed-icon-color: #5b21b6;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #5b21b6;
}
body.dark .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label,
body .dark .mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-mdc-radio-button.mat-warn,
body .dark .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #ef4444;
}
body.dark .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label,
body .dark .mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-mdc-slider,
body .dark .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
body.dark .mat-mdc-slider.mat-primary,
body .dark .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #171717;
  --mdc-slider-focus-handle-color: #171717;
  --mdc-slider-hover-handle-color: #171717;
  --mdc-slider-active-track-color: #171717;
  --mdc-slider-inactive-track-color: #171717;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #171717;
  --mat-mdc-slider-ripple-color: #171717;
  --mat-mdc-slider-hover-ripple-color: rgba(23, 23, 23, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(23, 23, 23, 0.2);
}
body.dark .mat-mdc-slider.mat-accent,
body .dark .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #5b21b6;
  --mdc-slider-focus-handle-color: #5b21b6;
  --mdc-slider-hover-handle-color: #5b21b6;
  --mdc-slider-active-track-color: #5b21b6;
  --mdc-slider-inactive-track-color: #5b21b6;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #5b21b6;
  --mat-mdc-slider-ripple-color: #5b21b6;
  --mat-mdc-slider-hover-ripple-color: rgba(91, 33, 182, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(91, 33, 182, 0.2);
}
body.dark .mat-mdc-slider.mat-warn,
body .dark .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #ef4444;
  --mdc-slider-focus-handle-color: #ef4444;
  --mdc-slider-hover-handle-color: #ef4444;
  --mdc-slider-active-track-color: #ef4444;
  --mdc-slider-inactive-track-color: #ef4444;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ef4444;
  --mat-mdc-slider-ripple-color: #ef4444;
  --mat-mdc-slider-hover-ripple-color: rgba(239, 68, 68, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(239, 68, 68, 0.2);
}
body.dark .mdc-menu-surface,
body .dark .mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #424242);
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item__primary-text,
body .dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark .mdc-list-item__secondary-text,
body .dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
body.dark .mdc-list-item__overline-text,
body .dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
body.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
body.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
body.dark .mdc-list-item__end,
body .dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
body.dark .mdc-list-item--disabled .mdc-list-item__start,
body.dark .mdc-list-item--disabled .mdc-list-item__content,
body.dark .mdc-list-item--disabled .mdc-list-item__end,
body .dark .mdc-list-item--disabled .mdc-list-item__start,
body .dark .mdc-list-item--disabled .mdc-list-item__content,
body .dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
body.dark .mdc-list-item--disabled .mdc-list-item__primary-text,
body .dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
body .dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled .mdc-list-item__overline-text,
body .dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
body .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--selected .mdc-list-item__primary-text,
body.dark .mdc-list-item--activated .mdc-list-item__primary-text,
body .dark .mdc-list-item--selected .mdc-list-item__primary-text,
body .dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #171717);
}
body.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #171717);
}
body.dark .mdc-deprecated-list-group__subheader,
body .dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark .mdc-list-divider::after,
body .dark .mdc-list-divider::after {
  border-bottom-color: white;
}
body.dark .mdc-list-divider,
body .dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
body.dark .mat-mdc-menu-item[disabled],
body.dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
body.dark .mat-mdc-menu-item[disabled] .mat-icon-no-color,
body .dark .mat-mdc-menu-item[disabled],
body .dark .mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
body .dark .mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(255, 255, 255, 0.5));
}
body.dark .mat-mdc-menu-item .mat-icon-no-color,
body.dark .mat-mdc-menu-submenu-icon,
body .dark .mat-mdc-menu-item .mat-icon-no-color,
body .dark .mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark .mat-mdc-menu-item:hover:not([disabled]),
body.dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
body.dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
body.dark .mat-mdc-menu-item-highlighted:not([disabled]),
body .dark .mat-mdc-menu-item:hover:not([disabled]),
body .dark .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
body .dark .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
body .dark .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.08);
}
body.dark .mat-mdc-list-item-interactive::before,
body .dark .mat-mdc-list-item-interactive::before {
  background: white;
}
body.dark .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before,
body .dark .mat-mdc-list-item-interactive:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.08;
}
body.dark .mat-mdc-list-item-interactive:focus::before,
body .dark .mat-mdc-list-item-interactive:focus::before {
  opacity: 0.24;
}
body.dark .mat-mdc-list-item-interactive.mdc-list-item--selected::before,
body .dark .mat-mdc-list-item-interactive.mdc-list-item--selected::before {
  background: #171717;
  opacity: 0.08;
}
body.dark .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before,
body .dark .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus):not(.mdc-list-item--disabled):hover::before {
  opacity: 0.12;
}
body.dark .mdc-list-item__primary-text,
body .dark .mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark .mdc-list-item__secondary-text,
body .dark .mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(255, 255, 255, 0.7));
}
body.dark .mdc-list-item__overline-text,
body .dark .mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
body.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}
body.dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark .mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .dark .mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(255, 255, 255, 0.5));
}
body.dark .mdc-list-item__end,
body .dark .mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(255, 255, 255, 0.5));
}
body.dark .mdc-list-item--disabled .mdc-list-item__start,
body.dark .mdc-list-item--disabled .mdc-list-item__content,
body.dark .mdc-list-item--disabled .mdc-list-item__end,
body .dark .mdc-list-item--disabled .mdc-list-item__start,
body .dark .mdc-list-item--disabled .mdc-list-item__content,
body .dark .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}
body.dark .mdc-list-item--disabled .mdc-list-item__primary-text,
body .dark .mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled .mdc-list-item__secondary-text,
body .dark .mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled .mdc-list-item__overline-text,
body .dark .mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end,
body .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end,
body .dark .mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mdc-list-item--selected .mdc-list-item__primary-text,
body.dark .mdc-list-item--activated .mdc-list-item__primary-text,
body .dark .mdc-list-item--selected .mdc-list-item__primary-text,
body .dark .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #171717);
}
body.dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body.dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
body .dark .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #171717);
}
body.dark .mdc-deprecated-list-group__subheader,
body .dark .mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark .mdc-list-divider::after,
body .dark .mdc-list-divider::after {
  border-bottom-color: white;
}
body.dark .mdc-list-divider,
body .dark .mdc-list-divider {
  background-color: rgba(255, 255, 255, 0.2);
}
body.dark .mat-mdc-list-option .mdc-list-item__start, body.dark .mat-mdc-list-option .mdc-list-item__end,
body .dark .mat-mdc-list-option .mdc-list-item__start,
body .dark .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #171717;
  --mdc-checkbox-selected-hover-icon-color: #171717;
  --mdc-checkbox-selected-icon-color: #171717;
  --mdc-checkbox-selected-pressed-icon-color: #171717;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
body.dark .mat-mdc-list-option .mdc-list-item__start, body.dark .mat-mdc-list-option .mdc-list-item__end,
body .dark .mat-mdc-list-option .mdc-list-item__start,
body .dark .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #171717;
  --mdc-radio-selected-hover-icon-color: #171717;
  --mdc-radio-selected-icon-color: #171717;
  --mdc-radio-selected-pressed-icon-color: #171717;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #171717;
}
body.dark .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, body.dark .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label,
body .dark .mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label,
body .dark .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-mdc-list-option.mat-accent .mdc-list-item__start, body.dark .mat-mdc-list-option.mat-accent .mdc-list-item__end,
body .dark .mat-mdc-list-option.mat-accent .mdc-list-item__start,
body .dark .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #5b21b6;
  --mdc-checkbox-selected-hover-icon-color: #5b21b6;
  --mdc-checkbox-selected-icon-color: #5b21b6;
  --mdc-checkbox-selected-pressed-icon-color: #5b21b6;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
body.dark .mat-mdc-list-option.mat-accent .mdc-list-item__start, body.dark .mat-mdc-list-option.mat-accent .mdc-list-item__end,
body .dark .mat-mdc-list-option.mat-accent .mdc-list-item__start,
body .dark .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #5b21b6;
  --mdc-radio-selected-hover-icon-color: #5b21b6;
  --mdc-radio-selected-icon-color: #5b21b6;
  --mdc-radio-selected-pressed-icon-color: #5b21b6;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #5b21b6;
}
body.dark .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, body.dark .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label,
body .dark .mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label,
body .dark .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-mdc-list-option.mat-warn .mdc-list-item__start, body.dark .mat-mdc-list-option.mat-warn .mdc-list-item__end,
body .dark .mat-mdc-list-option.mat-warn .mdc-list-item__start,
body .dark .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
body.dark .mat-mdc-list-option.mat-warn .mdc-list-item__start, body.dark .mat-mdc-list-option.mat-warn .mdc-list-item__end,
body .dark .mat-mdc-list-option.mat-warn .mdc-list-item__start,
body .dark .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #ef4444;
  --mdc-radio-selected-hover-icon-color: #ef4444;
  --mdc-radio-selected-icon-color: #ef4444;
  --mdc-radio-selected-pressed-icon-color: #ef4444;
  --mat-mdc-radio-ripple-color: #fff;
  --mat-mdc-radio-checked-ripple-color: #ef4444;
}
body.dark .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, body.dark .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label,
body .dark .mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label,
body .dark .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-mdc-paginator,
body .dark .mat-mdc-paginator {
  background: #424242;
  color: rgba(255, 255, 255, 0.87);
}
body.dark .mat-mdc-paginator-icon,
body .dark .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.54);
}
body.dark .mat-mdc-paginator-decrement,
body.dark .mat-mdc-paginator-increment,
body .dark .mat-mdc-paginator-decrement,
body .dark .mat-mdc-paginator-increment {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
  border-right: 2px solid rgba(255, 255, 255, 0.54);
}
body.dark .mat-mdc-paginator-first,
body.dark .mat-mdc-paginator-last,
body .dark .mat-mdc-paginator-first,
body .dark .mat-mdc-paginator-last {
  border-top: 2px solid rgba(255, 255, 255, 0.54);
}
body.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
body.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
body.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
body.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last,
body .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
body .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
body .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
body .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(255, 255, 255, 0.12);
}
body.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon,
body .dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(255, 255, 255, 0.12);
}
body.dark .mat-mdc-tab, body.dark .mat-mdc-tab-link,
body .dark .mat-mdc-tab,
body .dark .mat-mdc-tab-link {
  background-color: transparent;
}
body.dark .mat-mdc-tab .mdc-tab__text-label, body.dark .mat-mdc-tab-link .mdc-tab__text-label,
body .dark .mat-mdc-tab .mdc-tab__text-label,
body .dark .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
body.dark .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
body.dark .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, body.dark .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
body.dark .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element,
body .dark .mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
body .dark .mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element,
body .dark .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
body .dark .mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #171717;
}
body.dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .dark .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #171717);
}
body.dark .mdc-tab__ripple::before,
body.dark .mat-mdc-tab .mat-ripple-element,
body.dark .mat-mdc-tab-header-pagination .mat-ripple-element,
body.dark .mat-mdc-tab-link .mat-ripple-element,
body .dark .mdc-tab__ripple::before,
body .dark .mat-mdc-tab .mat-ripple-element,
body .dark .mat-mdc-tab-header-pagination .mat-ripple-element,
body .dark .mat-mdc-tab-link .mat-ripple-element {
  background-color: #171717;
}
body.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #5b21b6;
}
body.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #5b21b6);
}
body.dark .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
body.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
body.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
body.dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, body.dark .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
body.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
body.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
body.dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element,
body .dark .mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
body .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
body .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
body .dark .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
body .dark .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
body .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
body .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
body .dark .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #5b21b6;
}
body.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, body.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
body .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #ef4444;
}
body.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, body.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
body .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #ef4444);
}
body.dark .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
body.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
body.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
body.dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, body.dark .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
body.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
body.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
body.dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element,
body .dark .mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
body .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
body .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
body .dark .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
body .dark .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
body .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
body .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
body .dark .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #ef4444;
}
body.dark .mat-mdc-tab-group.mat-background-primary, body.dark .mat-mdc-tab-nav-bar.mat-background-primary,
body .dark .mat-mdc-tab-group.mat-background-primary,
body .dark .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #171717;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
body.dark .mat-mdc-tab-group.mat-background-accent, body.dark .mat-mdc-tab-nav-bar.mat-background-accent,
body .dark .mat-mdc-tab-group.mat-background-accent,
body .dark .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #5b21b6;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
body.dark .mat-mdc-tab-group.mat-background-warn, body.dark .mat-mdc-tab-nav-bar.mat-background-warn,
body .dark .mat-mdc-tab-group.mat-background-warn,
body .dark .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #ef4444;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
body.dark .mat-mdc-tab-header-pagination-chevron,
body .dark .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #fff);
}
body.dark .mat-mdc-checkbox .mdc-form-field,
body .dark .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
body.dark .mat-mdc-checkbox .mat-ripple-element,
body .dark .mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
body.dark .mat-mdc-checkbox .mdc-checkbox__ripple,
body .dark .mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #fff;
}
body.dark .mat-mdc-checkbox.mat-primary,
body .dark .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #171717;
  --mdc-checkbox-selected-hover-icon-color: #171717;
  --mdc-checkbox-selected-icon-color: #171717;
  --mdc-checkbox-selected-pressed-icon-color: #171717;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
body.dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
body .dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(23, 23, 23, 0.1);
}
body.dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
body .dark .mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #171717;
}
body.dark .mat-mdc-checkbox.mat-accent,
body .dark .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #5b21b6;
  --mdc-checkbox-selected-hover-icon-color: #5b21b6;
  --mdc-checkbox-selected-icon-color: #5b21b6;
  --mdc-checkbox-selected-pressed-icon-color: #5b21b6;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
body.dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
body .dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(91, 33, 182, 0.1);
}
body.dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
body .dark .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #5b21b6;
}
body.dark .mat-mdc-checkbox.mat-warn,
body .dark .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ef4444;
  --mdc-checkbox-selected-hover-icon-color: #ef4444;
  --mdc-checkbox-selected-icon-color: #ef4444;
  --mdc-checkbox-selected-pressed-icon-color: #ef4444;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
body.dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element,
body .dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(239, 68, 68, 0.1);
}
body.dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple,
body .dark .mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #ef4444;
}
body.dark .mat-mdc-checkbox-disabled label,
body .dark .mat-mdc-checkbox-disabled label {
  color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-mdc-button.mat-unthemed,
body .dark .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
body.dark .mat-mdc-button.mat-primary,
body .dark .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #171717;
}
body.dark .mat-mdc-button.mat-accent,
body .dark .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #5b21b6;
}
body.dark .mat-mdc-button.mat-warn,
body .dark .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #ef4444;
}
body.dark .mat-mdc-button[disabled][disabled],
body .dark .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-mdc-unelevated-button.mat-unthemed,
body .dark .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: #fff;
}
body.dark .mat-mdc-unelevated-button.mat-primary,
body .dark .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #171717;
  --mdc-filled-button-label-text-color: #fff;
}
body.dark .mat-mdc-unelevated-button.mat-accent,
body .dark .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #5b21b6;
  --mdc-filled-button-label-text-color: #fff;
}
body.dark .mat-mdc-unelevated-button.mat-warn,
body .dark .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #ef4444;
  --mdc-filled-button-label-text-color: #fff;
}
body.dark .mat-mdc-unelevated-button[disabled][disabled],
body .dark .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-mdc-raised-button.mat-unthemed,
body .dark .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: #fff;
}
body.dark .mat-mdc-raised-button.mat-primary,
body .dark .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #171717;
  --mdc-protected-button-label-text-color: #fff;
}
body.dark .mat-mdc-raised-button.mat-accent,
body .dark .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #5b21b6;
  --mdc-protected-button-label-text-color: #fff;
}
body.dark .mat-mdc-raised-button.mat-warn,
body .dark .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #ef4444;
  --mdc-protected-button-label-text-color: #fff;
}
body.dark .mat-mdc-raised-button[disabled][disabled],
body .dark .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
body.dark .mat-mdc-outlined-button,
body .dark .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
body.dark .mat-mdc-outlined-button.mat-unthemed,
body .dark .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
body.dark .mat-mdc-outlined-button.mat-primary,
body .dark .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #171717;
}
body.dark .mat-mdc-outlined-button.mat-accent,
body .dark .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #5b21b6;
}
body.dark .mat-mdc-outlined-button.mat-warn,
body .dark .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #ef4444;
}
body.dark .mat-mdc-outlined-button[disabled][disabled],
body .dark .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
body.dark .mat-mdc-button, body.dark .mat-mdc-outlined-button,
body .dark .mat-mdc-button,
body .dark .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
body.dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark .mat-mdc-button.mat-primary, body.dark .mat-mdc-outlined-button.mat-primary,
body .dark .mat-mdc-button.mat-primary,
body .dark .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #171717;
  --mat-mdc-button-ripple-color: rgba(23, 23, 23, 0.1);
}
body.dark .mat-mdc-button.mat-accent, body.dark .mat-mdc-outlined-button.mat-accent,
body .dark .mat-mdc-button.mat-accent,
body .dark .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #5b21b6;
  --mat-mdc-button-ripple-color: rgba(91, 33, 182, 0.1);
}
body.dark .mat-mdc-button.mat-warn, body.dark .mat-mdc-outlined-button.mat-warn,
body .dark .mat-mdc-button.mat-warn,
body .dark .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
body.dark .mat-mdc-raised-button, body.dark .mat-mdc-unelevated-button,
body .dark .mat-mdc-raised-button,
body .dark .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
body.dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark .mat-mdc-raised-button.mat-primary, body.dark .mat-mdc-unelevated-button.mat-primary,
body .dark .mat-mdc-raised-button.mat-primary,
body .dark .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark .mat-mdc-raised-button.mat-accent, body.dark .mat-mdc-unelevated-button.mat-accent,
body .dark .mat-mdc-raised-button.mat-accent,
body .dark .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark .mat-mdc-raised-button.mat-warn, body.dark .mat-mdc-unelevated-button.mat-warn,
body .dark .mat-mdc-raised-button.mat-warn,
body .dark .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark .mat-mdc-icon-button,
body .dark .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
body.dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark .mat-mdc-icon-button.mat-primary,
body .dark .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #171717;
  --mat-mdc-button-ripple-color: rgba(23, 23, 23, 0.1);
}
body.dark .mat-mdc-icon-button.mat-accent,
body .dark .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #5b21b6;
  --mat-mdc-button-ripple-color: rgba(91, 33, 182, 0.1);
}
body.dark .mat-mdc-icon-button.mat-warn,
body .dark .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #ef4444;
  --mat-mdc-button-ripple-color: rgba(239, 68, 68, 0.1);
}
body.dark .mat-mdc-icon-button.mat-primary,
body .dark .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #171717;
}
body.dark .mat-mdc-icon-button.mat-accent,
body .dark .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #5b21b6;
}
body.dark .mat-mdc-icon-button.mat-warn,
body .dark .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #ef4444;
}
body.dark .mat-mdc-icon-button[disabled][disabled],
body .dark .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-mdc-fab, body.dark .mat-mdc-mini-fab,
body .dark .mat-mdc-fab,
body .dark .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
body.dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, body.dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
body .dark .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
body.dark .mat-mdc-fab.mat-primary, body.dark .mat-mdc-mini-fab.mat-primary,
body .dark .mat-mdc-fab.mat-primary,
body .dark .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark .mat-mdc-fab.mat-accent, body.dark .mat-mdc-mini-fab.mat-accent,
body .dark .mat-mdc-fab.mat-accent,
body .dark .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark .mat-mdc-fab.mat-warn, body.dark .mat-mdc-mini-fab.mat-warn,
body .dark .mat-mdc-fab.mat-warn,
body .dark .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
body.dark .mat-mdc-fab.mat-unthemed, body.dark .mat-mdc-mini-fab.mat-unthemed,
body .dark .mat-mdc-fab.mat-unthemed,
body .dark .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #424242;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
body.dark .mat-mdc-fab.mat-primary, body.dark .mat-mdc-mini-fab.mat-primary,
body .dark .mat-mdc-fab.mat-primary,
body .dark .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #171717;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
body.dark .mat-mdc-fab.mat-accent, body.dark .mat-mdc-mini-fab.mat-accent,
body .dark .mat-mdc-fab.mat-accent,
body .dark .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #5b21b6;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
body.dark .mat-mdc-fab.mat-warn, body.dark .mat-mdc-mini-fab.mat-warn,
body .dark .mat-mdc-fab.mat-warn,
body .dark .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #ef4444;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
body.dark .mat-mdc-fab[disabled][disabled], body.dark .mat-mdc-mini-fab[disabled][disabled],
body .dark .mat-mdc-fab[disabled][disabled],
body .dark .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-mdc-snack-bar-container,
body .dark .mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mdc-snackbar-container-color: #d9d9d9;
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
}
body.dark .mdc-data-table,
body .dark .mdc-data-table {
  background-color: var(--mdc-theme-surface, #424242);
  border-color: rgba(255, 255, 255, 0.12);
}
body.dark .mdc-data-table__row,
body .dark .mdc-data-table__row {
  background-color: inherit;
}
body.dark .mdc-data-table__header-cell,
body .dark .mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #424242);
}
body.dark .mdc-data-table__row--selected,
body .dark .mdc-data-table__row--selected {
  background-color: rgba(23, 23, 23, 0.04);
}
body.dark .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
body.dark .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
body.dark .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing,
body .dark .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
body .dark .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
body .dark .mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.12);
}
body.dark .mdc-data-table__cell,
body.dark .mdc-data-table__header-cell,
body .dark .mdc-data-table__cell,
body .dark .mdc-data-table__header-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
body.dark .mdc-data-table__pagination,
body .dark .mdc-data-table__pagination {
  border-top-color: rgba(255, 255, 255, 0.12);
}
body.dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover,
body .dark .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(255, 255, 255, 0.04);
}
body.dark .mdc-data-table__header-cell,
body .dark .mdc-data-table__header-cell {
  color: rgba(255, 255, 255, 0.87);
}
body.dark .mdc-data-table__pagination-total,
body.dark .mdc-data-table__pagination-rows-per-page-label,
body.dark .mdc-data-table__cell,
body .dark .mdc-data-table__pagination-total,
body .dark .mdc-data-table__pagination-rows-per-page-label,
body .dark .mdc-data-table__cell {
  color: rgba(255, 255, 255, 0.87);
}
[dir=rtl] body.dark .mdc-data-table__pagination-button .mdc-button__icon, [dir=rtl] body .dark .mdc-data-table__pagination-button .mdc-button__icon, body.dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl], body .dark .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

body.dark .mat-mdc-table,
body .dark .mat-mdc-table {
  background: #424242;
}
body.dark .mat-mdc-progress-spinner,
body .dark .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #171717;
}
body.dark .mat-mdc-progress-spinner.mat-accent,
body .dark .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #5b21b6;
}
body.dark .mat-mdc-progress-spinner.mat-warn,
body .dark .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #ef4444;
}
body.dark .mat-badge-content,
body .dark .mat-badge-content {
  color: white;
  background: #171717;
}
.cdk-high-contrast-active body.dark .mat-badge-content, .cdk-high-contrast-active body .dark .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

body.dark .mat-badge-accent .mat-badge-content,
body .dark .mat-badge-accent .mat-badge-content {
  background: #5b21b6;
  color: white;
}
body.dark .mat-badge-warn .mat-badge-content,
body .dark .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ef4444;
}
body.dark .mat-badge-disabled .mat-badge-content,
body .dark .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-bottom-sheet-container,
body .dark .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
body.dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
body.dark .mat-button-toggle-group:not([class*=mat-elevation-z]),
body .dark .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
body .dark .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
body.dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]),
body .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
body .dark .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
body.dark .mat-button-toggle,
body .dark .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-button-toggle .mat-button-toggle-focus-overlay,
body .dark .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
body.dark .mat-button-toggle-appearance-standard,
body .dark .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
body.dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay,
body .dark .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
body.dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
body .dark .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
body.dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
body .dark [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
body.dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle,
body .dark .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
body.dark .mat-button-toggle-checked,
body .dark .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
body.dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard,
body .dark .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
body.dark .mat-button-toggle-disabled,
body .dark .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
body.dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard,
body .dark .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
body.dark .mat-button-toggle-disabled.mat-button-toggle-checked,
body .dark .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
body.dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
body.dark .mat-button-toggle-group-appearance-standard,
body .dark .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
body .dark .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
body.dark .mat-calendar-arrow,
body .dark .mat-calendar-arrow {
  fill: white;
}
body.dark .mat-datepicker-toggle,
body.dark .mat-datepicker-content .mat-calendar-next-button,
body.dark .mat-datepicker-content .mat-calendar-previous-button,
body .dark .mat-datepicker-toggle,
body .dark .mat-datepicker-content .mat-calendar-next-button,
body .dark .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
body.dark .mat-calendar-table-header-divider::after,
body .dark .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
body.dark .mat-calendar-table-header,
body.dark .mat-calendar-body-label,
body .dark .mat-calendar-table-header,
body .dark .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
body.dark .mat-calendar-body-cell-content,
body.dark .mat-date-range-input-separator,
body .dark .mat-calendar-body-cell-content,
body .dark .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
body.dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-form-field-disabled .mat-date-range-input-separator,
body .dark .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-calendar-body-in-preview,
body .dark .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
body.dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
body.dark .mat-calendar-body-in-range::before,
body .dark .mat-calendar-body-in-range::before {
  background: rgba(23, 23, 23, 0.2);
}
body.dark .mat-calendar-body-comparison-identical,
body.dark .mat-calendar-body-in-comparison-range::before,
body .dark .mat-calendar-body-comparison-identical,
body .dark .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.dark .mat-calendar-body-comparison-bridge-start::before,
body.dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
body .dark .mat-calendar-body-comparison-bridge-start::before,
body .dark [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(23, 23, 23, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark .mat-calendar-body-comparison-bridge-end::before,
body.dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
body .dark .mat-calendar-body-comparison-bridge-end::before,
body .dark [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(23, 23, 23, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
body .dark .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body .dark .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
body .dark .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body .dark .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.dark .mat-calendar-body-selected,
body .dark .mat-calendar-body-selected {
  background-color: #171717;
  color: white;
}
body.dark .mat-calendar-body-disabled > .mat-calendar-body-selected,
body .dark .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(23, 23, 23, 0.4);
}
body.dark .mat-calendar-body-today.mat-calendar-body-selected,
body .dark .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
body.dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(23, 23, 23, 0.3);
}
@media (hover: hover) {
  body.dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  body .dark .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(23, 23, 23, 0.3);
  }
}
body.dark .mat-datepicker-content,
body .dark .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(91, 33, 182, 0.2);
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
body.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
body .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(91, 33, 182, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
body.dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
body .dark .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(91, 33, 182, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #5b21b6;
  color: white;
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(91, 33, 182, 0.4);
}
body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
body.dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(91, 33, 182, 0.3);
}
@media (hover: hover) {
  body.dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  body .dark .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(91, 33, 182, 0.3);
  }
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(239, 68, 68, 0.2);
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
body.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
body .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(239, 68, 68, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
body.dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
body .dark .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(239, 68, 68, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ef4444;
  color: white;
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(239, 68, 68, 0.4);
}
body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected,
body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
body.dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body.dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
body .dark .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(239, 68, 68, 0.3);
}
@media (hover: hover) {
  body.dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  body .dark .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(239, 68, 68, 0.3);
  }
}
body.dark .mat-datepicker-content-touch,
body .dark .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
body.dark .mat-datepicker-toggle-active,
body .dark .mat-datepicker-toggle-active {
  color: #171717;
}
body.dark .mat-datepicker-toggle-active.mat-accent,
body .dark .mat-datepicker-toggle-active.mat-accent {
  color: #5b21b6;
}
body.dark .mat-datepicker-toggle-active.mat-warn,
body .dark .mat-datepicker-toggle-active.mat-warn {
  color: #ef4444;
}
body.dark .mat-date-range-input-inner[disabled],
body .dark .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
body.dark .mat-divider,
body .dark .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
body.dark .mat-divider-vertical,
body .dark .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
body.dark .mat-expansion-panel,
body .dark .mat-expansion-panel {
  background: #424242;
  color: white;
}
body.dark .mat-expansion-panel:not([class*=mat-elevation-z]),
body .dark .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
body.dark .mat-action-row,
body .dark .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
body.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), body.dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), body.dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]),
body .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
body .dark .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),
body .dark .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  body.dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover,
  body .dark .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
body.dark .mat-expansion-panel-header-title,
body .dark .mat-expansion-panel-header-title {
  color: white;
}
body.dark .mat-expansion-panel-header-description,
body.dark .mat-expansion-indicator::after,
body .dark .mat-expansion-panel-header-description,
body .dark .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
body.dark .mat-expansion-panel-header[aria-disabled=true],
body .dark .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
body.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
body.dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description,
body .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
body .dark .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
body.dark .mat-icon.mat-primary,
body .dark .mat-icon.mat-primary {
  color: #171717;
}
body.dark .mat-icon.mat-accent,
body .dark .mat-icon.mat-accent {
  color: #5b21b6;
}
body.dark .mat-icon.mat-warn,
body .dark .mat-icon.mat-warn {
  color: #ef4444;
}
body.dark .mat-drawer-container,
body .dark .mat-drawer-container {
  background-color: #303030;
  color: white;
}
body.dark .mat-drawer,
body .dark .mat-drawer {
  background-color: #424242;
  color: white;
}
body.dark .mat-drawer.mat-drawer-push,
body .dark .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
body.dark .mat-drawer:not(.mat-drawer-side),
body .dark .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
body.dark .mat-drawer-side,
body .dark .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
body.dark .mat-drawer-side.mat-drawer-end,
body .dark .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
body.dark [dir=rtl] .mat-drawer-side,
body .dark [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
body.dark [dir=rtl] .mat-drawer-side.mat-drawer-end,
body .dark [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
body.dark .mat-drawer-backdrop.mat-drawer-shown,
body .dark .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
body.dark .mat-step-header.cdk-keyboard-focused, body.dark .mat-step-header.cdk-program-focused, body.dark .mat-step-header:hover:not([aria-disabled]), body.dark .mat-step-header:hover[aria-disabled=false],
body .dark .mat-step-header.cdk-keyboard-focused,
body .dark .mat-step-header.cdk-program-focused,
body .dark .mat-step-header:hover:not([aria-disabled]),
body .dark .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
body.dark .mat-step-header:hover[aria-disabled=true],
body .dark .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  body.dark .mat-step-header:hover,
  body .dark .mat-step-header:hover {
    background: none;
  }
}
body.dark .mat-step-header .mat-step-label,
body.dark .mat-step-header .mat-step-optional,
body .dark .mat-step-header .mat-step-label,
body .dark .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
body.dark .mat-step-header .mat-step-icon,
body .dark .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
body.dark .mat-step-header .mat-step-icon-selected,
body.dark .mat-step-header .mat-step-icon-state-done,
body.dark .mat-step-header .mat-step-icon-state-edit,
body .dark .mat-step-header .mat-step-icon-selected,
body .dark .mat-step-header .mat-step-icon-state-done,
body .dark .mat-step-header .mat-step-icon-state-edit {
  background-color: #171717;
  color: white;
}
body.dark .mat-step-header.mat-accent .mat-step-icon,
body .dark .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
body.dark .mat-step-header.mat-accent .mat-step-icon-selected,
body.dark .mat-step-header.mat-accent .mat-step-icon-state-done,
body.dark .mat-step-header.mat-accent .mat-step-icon-state-edit,
body .dark .mat-step-header.mat-accent .mat-step-icon-selected,
body .dark .mat-step-header.mat-accent .mat-step-icon-state-done,
body .dark .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #5b21b6;
  color: white;
}
body.dark .mat-step-header.mat-warn .mat-step-icon,
body .dark .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
body.dark .mat-step-header.mat-warn .mat-step-icon-selected,
body.dark .mat-step-header.mat-warn .mat-step-icon-state-done,
body.dark .mat-step-header.mat-warn .mat-step-icon-state-edit,
body .dark .mat-step-header.mat-warn .mat-step-icon-selected,
body .dark .mat-step-header.mat-warn .mat-step-icon-state-done,
body .dark .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ef4444;
  color: white;
}
body.dark .mat-step-header .mat-step-icon-state-error,
body .dark .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ef4444;
}
body.dark .mat-step-header .mat-step-label.mat-step-label-active,
body .dark .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
body.dark .mat-step-header .mat-step-label.mat-step-label-error,
body .dark .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ef4444;
}
body.dark .mat-stepper-horizontal, body.dark .mat-stepper-vertical,
body .dark .mat-stepper-horizontal,
body .dark .mat-stepper-vertical {
  background-color: #424242;
}
body.dark .mat-stepper-vertical-line::before,
body .dark .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
body.dark .mat-horizontal-stepper-header::before,
body.dark .mat-horizontal-stepper-header::after,
body.dark .mat-stepper-horizontal-line,
body .dark .mat-horizontal-stepper-header::before,
body .dark .mat-horizontal-stepper-header::after,
body .dark .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
body.dark .mat-sort-header-arrow,
body .dark .mat-sort-header-arrow {
  color: #c6c6c6;
}
body.dark .mat-toolbar,
body .dark .mat-toolbar {
  background: #212121;
  color: white;
}
body.dark .mat-toolbar.mat-primary,
body .dark .mat-toolbar.mat-primary {
  background: #171717;
  color: white;
}
body.dark .mat-toolbar.mat-accent,
body .dark .mat-toolbar.mat-accent {
  background: #5b21b6;
  color: white;
}
body.dark .mat-toolbar.mat-warn,
body .dark .mat-toolbar.mat-warn {
  background: #ef4444;
  color: white;
}
body.dark .mat-toolbar .mat-form-field-underline,
body.dark .mat-toolbar .mat-form-field-ripple,
body.dark .mat-toolbar .mat-focused .mat-form-field-ripple,
body .dark .mat-toolbar .mat-form-field-underline,
body .dark .mat-toolbar .mat-form-field-ripple,
body .dark .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
body.dark .mat-toolbar .mat-form-field-label,
body.dark .mat-toolbar .mat-focused .mat-form-field-label,
body.dark .mat-toolbar .mat-select-value,
body.dark .mat-toolbar .mat-select-arrow,
body.dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow,
body .dark .mat-toolbar .mat-form-field-label,
body .dark .mat-toolbar .mat-focused .mat-form-field-label,
body .dark .mat-toolbar .mat-select-value,
body .dark .mat-toolbar .mat-select-arrow,
body .dark .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
body.dark .mat-toolbar .mat-input-element,
body .dark .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
body.dark .mat-tree,
body .dark .mat-tree {
  background: #424242;
}
body.dark .mat-tree-node,
body.dark .mat-nested-tree-node,
body .dark .mat-tree-node,
body .dark .mat-nested-tree-node {
  color: white;
}