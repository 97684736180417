@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html, body {
    @apply bg-white text-black-900 h-full;
  }

  h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -4%;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -2%;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0;
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.5%;
  }

  h5 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.5%;
  }

  h6 {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.5%;
  }

  a {
    @apply text-purple-800 cursor-pointer;
  }

  a:hover {
    @apply text-purple-500 underline;
  }

  code {
    @apply w-full rounded-lg;

    &:not(.hljs) {
      @apply font-semibold;

      &:before,
      &:after {
        content: '`';
      }
    }
  }

  table {
    thead {
      th {
        text-align: left;
      }
    }

    tbody {
      tr:last-child {
        @apply border-b-0;
      }
    }

    tr {
      @apply border-b border-gray-200;
    }

    td, th {
      padding: 8px 14px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  pre {
    @apply flex;
  }
}
